import Selectable from './Selectable.tsx';
import {OptionGroup} from '../models/option-group.ts';
import {Option} from '../models/option.ts';
import {useState} from 'react';

export default function OptionGroupComponent({
  optionGroupProp,
  optionsSelected,
  handleOptionSelectedEvent
}: {
  optionGroupProp: OptionGroup,
  optionsSelected: Array<Option>,
  handleOptionSelectedEvent: any
}) {

  function renderOptionGroup(optionGroup: OptionGroup, level = 0) {

    const [optionChildrenVisibilityMapping, setOptionChildrenVisibilityMapping] = useState({});

    const levelToMarginTW = ['', 'ml-4', 'ml-8', 'ml-12', 'ml-16', 'ml-20']

    return <div key={`${optionGroup.uid}_${Math.random() * 10000}`} className={`my-4 ${levelToMarginTW[level]}`}>
        <span className={`font-bold my-4 ${level ? 'text-xs' : ''}`}>{optionGroup.name}{optionGroup.amountOptionsRequired ? <span className={'text-red-600'}> *</span> : ''}</span>
        {optionGroup.options?.map((option, it) => (
          <div key={`${option.name}_${Math.random() * (it+10000)}`}>
            <Selectable
              key={`${option.name}_${Math.random() * (it+10000)}`}
              group={optionGroup.name}
              type={optionGroup.type === 'addable' || optionGroup.amountOptionsRequired > 1 ? 'checkbox' : 'radio'}
              label={`${option.name} ${option.description ? (' - ' + option.description) : ''} ${option.price ? (' - €' + option.price) : ''}`}
              checked={!!optionsSelected?.find(o => option.name === o.name && option.price === o.price)}
              onSelected={e => {
                const optionChildrenVisibilityMappingObj = {...optionChildrenVisibilityMapping};
                optionChildrenVisibilityMappingObj[optionGroup.uid] = it;
                setOptionChildrenVisibilityMapping(optionChildrenVisibilityMappingObj);
                console.log('handleOptionSelectedEvent(e, option, optionGroup)', e, option, optionGroup);
                handleOptionSelectedEvent(e, option, optionGroup);
              }}
            ></Selectable>
            {option.optionGroups && option.optionGroups.length ?
              <div style={{'display': optionChildrenVisibilityMapping[optionGroup.uid] === it ? 'block' : 'none'}}>{option.optionGroups.map(og => renderOptionGroup(og, level + 1))}</div>
            : ''}
          </div>
        ))}
      </div>;
  }

  return renderOptionGroup(optionGroupProp);
}
