import loadGoogleMapsApi from "load-google-maps-api";
import { GOOGLE_KEY } from "../constants";
import type { Address } from "../models/submodels/address.ts";
import { Coordinates } from "../models/submodels/coordinates";
import httpHelper from "../utils/http-helper.ts";
import store from "../state-store.tsx";
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

// import AutocompletePrediction = google.maps.places.AutocompletePrediction;

class AddressService {
  geocoder: google.maps.Geocoder | null = null;
  storedAddress: Address | null = store.getState("address");

  constructor() {
    this.initGoogle();
  }

  async initGoogle() {
    await loadGoogleMapsApi({
      key: GOOGLE_KEY,
    });
    this.geocoder = new google.maps.Geocoder();
    if (this.storedAddress) {
      this.setAddress(this.storedAddress);
    } else {
      if (!store.getState("address")) {
        this.setCurrentAddressFromCurrentPosition();
      }
    }
    return true;
  }

  setAddress(address: Address) {
    console.log("setAddress address", address);
    store.setState("address", address);
  }

  observeCurrentAddress() {
    return store.observeState("address");
  }

  async setCurrentAddressFromCurrentPosition() {
    const currentPosition = await this.getCurrentPosition();
    if (currentPosition) {
      this.geocoder
        ?.geocode({
          location: new google.maps.LatLng(
            currentPosition.latitude || 0,
            currentPosition.longitude,
          ),
        })
        .then(
          (response: any) => {
            const address = response.results[0].formatted_address;
            const location = this.detailsToAddress(response.results[0]);
            location.addressLine1 = address;
            this.setAddress(location);
          },
          (error: any) => {
            console.error(error);
          },
        );
    }
  }

  getCurrentPosition(): Promise<Coordinates> {
    return new Promise((resolve) => {
      try {
        if (navigator?.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              resolve({ latitude, longitude } as Coordinates);
            },
            (err) => {
              console.error(err);
              // alert(err);
            },
          );
        } else {
          alert("Address not enabled or not supported.");
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    });
  }

  async getListOfPlaces(value: string) {
    const result = await httpHelper.bypassCors(
      "GET",
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURI(value)}&components=country:pt&key=${GOOGLE_KEY}`,
    );
    const predictions = result.predictions;
    const status = result.status;
    if (status === "OK") {
      const addresses: Address[] = [];
      predictions.forEach((prediction: AutocompletePrediction) => {
        addresses.push({
          addressLine1: prediction.description,
          placeId: prediction.place_id,
        } as Address);
      });
      return addresses;
    } else {
      throw (
        "Places Autocomplete was not successful for the following reason: " +
        status
      );
    }
  }

  async getAddressUsingPlace(placeId: string): Promise<Address> {
    const response = await httpHelper.bypassCors(
      "GET",
      `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${GOOGLE_KEY}`,
    );
    return this.detailsToAddress(response.result);
  }

  // async setAddressFromPlace(placeId: string) {
  //   const response = await httpHelper.bypassCors('GET', `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${GOOGLE_KEY}`)
  //   this.currentAddress$.next(this.detailsToAddress(response.result));
  // }

  detailsToAddress(details: any): Address {
    const location = {} as Address;
    location.addressLine1 = details?.name;
    details.address_components.map((info: any) => {
      if (info.types.indexOf("route") > -1) {
        // Address
        // location.addressLine1 = info.short_name;
      } else if (info.types.indexOf("locality") > -1) {
        location.local = info.short_name;
      } else if (info.types.indexOf("postal_code") > -1) {
        location.postCode = info.short_name;
      }
    });
    if (details && details.geometry && details.geometry.location) {
      location.coordinates = {} as Coordinates;
      location.coordinates.latitude =
        typeof details.geometry.location.lat === "number"
          ? details.geometry.location.lat
          : details.geometry.location.lat();
      location.coordinates.longitude =
        typeof details.geometry.location.lng === "number"
          ? details.geometry.location.lng
          : details.geometry.location.lng();
    }
    return location;
  }

  async getThatRoadDistanceInKmYo(point1: Coordinates, point2: Coordinates) {
    return new Promise<number>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const googlePoint1 = new google.maps.LatLng(
        point1.latitude,
        point1.longitude,
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const googlePoint2 = new google.maps.LatLng(
        point2.latitude,
        point2.longitude,
      );

      const request = {
        origins: [googlePoint1],
        destinations: [googlePoint2],
        travelMode: "DRIVING",
        avoidHighways: false,
        avoidTolls: true,
      } as google.maps.DistanceMatrixRequest;

      new google.maps.DistanceMatrixService().getDistanceMatrix(
        request,
        (response) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const status = response.rows[0].elements[0].status;
          if (status === "OK") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const lessDistance = response.rows[0].elements.sort((a, b) =>
              a.distance.value > b.distance.value ? 1 : 0,
            );
            const distanceInKm = lessDistance[0].distance.value / 1000;
            resolve(distanceInKm > 1 ? Math.round(distanceInKm) : 1);
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resolve(null);
          }
        },
      );
    });
  }

  async getRoadDistanceInKm(point1: Coordinates, point2: Coordinates) {
    return new Promise<number>((resolve) => {
      if (typeof google !== "undefined") {
        this.getThatRoadDistanceInKmYo(point1, point2).then(resolve);
      } else {
        return this.initGoogle().then(() =>
          this.getThatRoadDistanceInKmYo(point1, point2).then(resolve),
        );
      }
    });
  }

  // private setPostcodeFromLatLng(lat: number, lng: number) {
  //   this.httpHelper.bypassCors('GET', `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB3McyHg_YiFoz7eU7xAkQNA9cwEkjnPvE`)
  //     .then(result => {
  //       result.results[0].address_components.map((info) => {
  //         if (info.types.indexOf('postal_code') > -1) {
  //           this.addressForm.controls.postCode.setValue(info.short_name.split('-')[0]);
  //           if (info.short_name.split('-')[1]) {
  //             this.addressForm.controls.postCodeSuffix.setValue(info.short_name.split('-')[1]);
  //           }
  //         }
  //       });
  //     })
  //     .catch(error => log('error', error));
  // }
}

export default new AddressService();
