/* tslint:disable: max-line-length */
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {io, Socket} from 'socket.io-client';
import {API_URL} from '../constants';
import httpHelper from '../utils/http-helper.ts';

export class ScuverService {

  observingRecords: Map<string, BehaviorSubject<any>> = new Map();
  observingQueries: Map<string, BehaviorSubject<any>> = new Map();
  socket: Socket;

  constructor() {
    this.socket = io(API_URL);
    this.socket.on('connect_error', (err) => {
      console.error(`connect_error due to ${err.message}`);
    });
  }

  getCollection(collection: string): Promise<any> {
    return httpHelper.get(`${API_URL}/get/${collection}`);
  }

  getRecord(collection: string, uid: string): Promise<any> {
    if (uid) {
      return httpHelper.get(`${API_URL}/get/${collection}/${uid}`);
    } else {
      throw new Error('UID NOT PROVIDED TO GET RECORD');
    }
  }

  getRecordByProperty(collection: string, property: string, filterOp: any = '==', value: any) {
    return this.getRecordsByProperties(collection, [property], [filterOp], value ? [value] : []);
  }

  getRecordByMinifiedProperty(collection: string, property: string, value: any) {
    return httpHelper.get(`${API_URL}/getByMinifiedProp?collection=${collection}&prop=${property}&value=${encodeURI(JSON.stringify(value))}`);
  }

  getRecordByProperties(collection: string, properties: string[], filterOp: any | any[] = '==', values: any[]): Promise<any> {
    return this.getRecordsByProperties(collection, properties, filterOp, values);
  }

  getRecordsByProperty(collection: string, property: string, filterOp: any = '==', value: any): Promise<any[]> {
    return this.getRecordsByProperties(collection, [property], [filterOp], value ? [value] : []);
  }

  getRecordsByProperties(collection: string, properties: string[], filterOp: any | any[] = '==', values: any[]): Promise<any> {
    return httpHelper.get(`${API_URL}/getByProps?collection=${collection}&props=${encodeURI(JSON.stringify(properties))}&operators=${encodeURI(JSON.stringify(filterOp))}&values=${encodeURI(JSON.stringify(values))}`);
  }

  addOrUpdateRecord(collection: string, record: any) {
    return httpHelper.post(`${API_URL}/addOrUpdate`, {collection, record});
  }

  removeRecord(collection: string, uid: string) {
    return httpHelper.delete(`${API_URL}/${collection}/${uid}`);
  }

  removeOrder(uid: string) {
    return httpHelper.delete(`${API_URL}/deleteOrder/${uid}`);
  }

  observeRecordByProperty(collection: string, property: string, filterOp: any = '==', value: any): Observable<any> | undefined {
    return this.observeRecordsByProperties(collection, [property], [filterOp], value ? [value] : [])?.pipe(map(r => r[0]));
  }

  observeRecordByProperties(collection: string, properties: string[], filterOp: any | any[] = '==', values: any[]): Observable<any> | undefined {
    return this.observeRecordsByProperties(collection, properties, filterOp, values)?.pipe(map(r => r[0]));
  }

  observeRecordsByProperty(collection: string, property: string, filterOp: any = '==', value: any): Observable<any> | undefined {
    return this.observeRecordsByProperties(collection, [property], [filterOp], value ? [value] : []);
  }

  async isSuspended() {
    try {
      await httpHelper.get(`${API_URL}/get/food-types`);
    } catch (e: any) {
      console.log('PING ERROR', e);
      return e.status === 0;
    }
    return false;
  }

  sendSMS(phoneNumber: string, message: string) {
    return httpHelper.post(`${API_URL}/sendSMS`, {phoneNumber, message});
  }

  sendEmail(recipientAddress: string, subject = 'Tastic', body: string, isHTML = false) {
    return httpHelper.post(`${API_URL}/sendEmail`, {recipientAddress, subject, body, isHTML});
  }

  getOneWeekOrders() {
    return httpHelper.get(`${API_URL}/getOneWeekOrders`);
  }

  observeRecordsByProperties(collection: string, properties: string[], filterOp: any | any[] = '==', values: any[]): Observable<any> | undefined {
    const arrrgs = {
      collection,
      props: Array.isArray(properties) ? properties : [properties],
      operators: Array.isArray(filterOp) ? filterOp : [filterOp],
      values
    };
    const query = JSON.stringify(arrrgs);
    if (!this.observingQueries.get(query)) {
      const processNewRecord = (rec: any) => {
        let matches = true;
        arrrgs.props.forEach((prop, it) => {
          if (!this.checkIfMatches(rec, prop, arrrgs.operators[it], arrrgs.values[it])) {
            matches = false;
          }
        });
        if (matches) {
          const currentData = this.observingQueries.get(query)?.getValue();
          currentData.push(rec);
          this.observingQueries.get(query)?.next(currentData);
        }
      };
      this.observingQueries.set(query, new BehaviorSubject<Array<any>>([]));
      this.socket.on(`update:${collection}`, (rec) => {
        console.log(`update:${collection}:*`, rec.uid);
        const currentData = this.observingQueries.get(query)?.getValue();
        const index = currentData.findIndex((c: any) => c.uid === rec.uid);
        if (index !== -1) {
          currentData.splice(index, 1, rec);
        } else {
          processNewRecord(rec);
        }
        this.observingQueries.get(query)?.next(currentData);
      });
    }
    this.getRecordsByProperties(collection, properties, filterOp, values).then(data => {
      this.observingQueries.get(query)?.next(data);
    });
    return this.observingQueries.get(query);
  }

  checkIfMatches(obj: any, prop: string, operator: string, value: any) {
    const objectPropValue = this.deepFind(obj, prop);
    switch (operator) {
      case '==': {
        return objectPropValue === value;
      }
      case '!=': {
        return objectPropValue !== value;
      }
      case '>=': {
        return objectPropValue >= value;
      }
      case '<=': {
        return objectPropValue <= value;
      }
      case '>': {
        return objectPropValue > value;
      }
      case '<': {
        return objectPropValue < value;
      }
      case 'in': {
        let found;
        value.forEach((v: any) => {
          if (v === objectPropValue) {
            found = true;
          }
        });
        return found;
      }
      default: {
        return objectPropValue === value;
      }
    }
  }

  observeRecord(collection: string, uid: string): Observable<any> | undefined {
    if (!this.observingRecords.get(`update:${collection}:${uid}`)) {
      this.observingRecords.set(`update:${collection}:${uid}`, new BehaviorSubject<Array<any>>([]));
      this.socket.on(`update:${collection}`, (data) => {
        const listener = this.observingRecords.get(`update:${collection}:${data.uid}`);
        if (listener) {
          listener.next(data);
        }
      });
    }
    console.log('OBSERVE RECORD', uid);
    this.getRecord(collection, uid).then(data => {
      console.log('GET RECORD', data);
      if (uid) {
        this.observingRecords.get(`update:${collection}:${uid}`)?.next(data);
      }
    });
    return this.observingRecords.get(`update:${collection}:${uid}`);
  }

  deepFind(obj: any, path: string) {
    const paths = path.split('.');
    let  current = obj;
    let i;

    for (i = 0; i < paths.length; ++i) {
      if (current[paths[i]] === undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }
    return current;
  }
}

export default new ScuverService();
