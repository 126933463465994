import store from "../state-store";
import Dialog from "./Dialog";
import whatsapp from "../assets/images/whatsapp.png";
import { useState } from "react";

export default function Contacts() {
  const [open, setOpen] = useState(false);
  store.observeState("contactsOpen")?.subscribe((v) => v && setOpen(true));

  return (
    <Dialog
      show={open || false}
      onClose={() => store.setState("contactsOpen", false)}
      classes="bg-white overflow-y-auto overflow-x-hidden max-h-[96vh]"
    >
      <div className={"w-full bg-white min-h-[10em] text-gray-500"}>
        <p>Contacte-nos por Whatsapp: </p>
        <a
          href="https://wa.me/351912287967"
          target="_blank"
          className={"flex items-center m-4 space-x-4"}
        >
          <img className={"w-8"} src={whatsapp} />
          <span>Whatsapp Tastic</span>
        </a>
        <p>Ou por e-mail: </p>
        <a
          href="mailto:tasticpt@gmail.com"
          target="_blank"
          className={"flex items-center m-4 space-x-4"}
        >
          <span>tasticpt@gmail.com</span>
        </a>
      </div>
    </Dialog>
  );
}
