import {useEffect, useState} from 'react';
import {toast, Toaster} from 'react-hot-toast';
import {onMessageListener, requestPermission} from '../firebase';

function Notification() {
  const [,setNotification] = useState({ title: '', body: '' });
  useEffect(() => {
    const currentToken = localStorage.getItem('firebase_token');
    console.log('currentToken', currentToken);
    if (!currentToken) {
      requestPermission();
    }
    const unsubscribe = onMessageListener().then((payload) => {
      setNotification({
        // @ts-ignore
        title: payload?.notification?.title,
        // @ts-ignore
        body: payload?.notification?.body,
      });
       // @ts-ignore
      toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
        duration: 10000,
        position: 'top-right',
      });
});
    return () => {
      unsubscribe.catch((err) => console.log('failed: ', err));
    };
  }, []);
  return (
    <div>
      <Toaster />
    </div>
  );
}
export default Notification;
