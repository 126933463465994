import {ChangeEventHandler, FocusEventHandler, MouseEventHandler, ReactNode} from 'react';

export default function Input(props: {
  onChange?: ChangeEventHandler,
  onClick?: MouseEventHandler,
  onFocus?: FocusEventHandler,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEnter?: Function,
  type?: string,
  initialValue?: any,
  placeholder?:any,
  classes?: string,
  inputClasses?: string,
  icon?: ReactNode
}) {
  return (
    <div onClick={props.onClick} className={`flex flex-col md:flex-row relative mt-2 p-3 md:p-3 rounded-full shadow-sm bg-white ${props.classes}`}>
      <div className={''}>{props.icon}</div>
      <input
        type={props.type || 'text'}
        value={props.initialValue || ''}
        disabled={!props.onChange}
        readOnly={!props.onChange}
        onChange={props.onChange ? props.onChange : () => {}}
        onClick={props.onClick ? props.onClick : () => {}}
        onFocus={props.onFocus ? props.onFocus : () => {}}
        onKeyDown={props.onEnter ? e => {
          if (e.key === 'Enter' && props.onEnter) {
            props.onEnter();
          }
        } : () => {}}
        className={`whitespace-wrap bg-transparent font-semibold block border-none w-full rounded-md border-0 text-gray-800 placeholder:text-gray-400 md:text-xl focus:outline-0 border-transparent focus:border-transparent focus:ring-0 ${props.icon ? 'pl-10 md:pl-14' : ''} ${props.inputClasses}`}
        placeholder={props.placeholder}
      />
    </div>
  );
}
