// import sandwich from '../assets/images/sandwich_primary.svg';
import { useNavigate } from "react-router-dom";
import Image from "./Image.tsx";
import store from "../state-store.tsx";
import { useState } from "react";

export default function ShopSubHeader() {
  const [shop, setShop] = useState(null);
  store.observeState("shop")?.subscribe((s) => {
    s && s.uid && (!shop || s.uid !== shop?.uid) ? setShop(s) : "";
  });
  const navigate = useNavigate();

  return (
    <div className="flex-col w-full mt-4" onClick={() => navigate("/shop")}>
      <Image
        src={shop?.photoUrl}
        classes={"w-screen h-48 object-cover border border-[#ccc]"}
      />
      <h2 className="text-3xl text-gray-800 pl-4 mt-4">{shop?.name}</h2>
      <p className="text-gray-500 pl-4">
        {shop?.address?.addressLine1} {shop?.address?.addressLine2},{" "}
        {shop?.address?.postCode}, {shop?.address?.local}
      </p>
    </div>
  );
}
