import {
  ConfirmationResult,
  GoogleAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import {ReplaySubject} from "rxjs";
import {take} from "rxjs/operators";
import {IS_DEV} from "../constants";
import {auth} from "../firebase.tsx";
import type {User} from "../models/user.ts";
import store from "../state-store.tsx";
import scuverService from "./scuver.service.ts";
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";
import {Capacitor} from "@capacitor/core";

export class AuthService {
  private authUser$ = new ReplaySubject<any>(1);
  private user$ = new ReplaySubject<User>(1);
  private confirmationResult: ConfirmationResult | null = null;
  verificationId;

  constructor() {
    auth.languageCode = window?.navigator?.language || "pt";
    this.trackAuthUser();
  }

  private userChanged(authUser) {
    if (authUser) {
      this.authUser$.next(authUser);
      console.log("AUTH USER", authUser);
      if (!authUser) {
        setTimeout(() => {
          this.user$.next(null);
          // store.setState('user', null);
        }, 1000);
        return;
      }

      if (authUser.isAnonymous) {
        console.log("USER ANON", authUser);
        store.setState("isAnon", true);
        this.user$.next({uid: authUser.uid, name: "Anonymous"} as User);
        // store.setState('user', {uid: authUser.uid, name: 'Anonymous'} as User);
      } else {
        store.setState("isAnon", false);
        scuverService.getRecord("users", authUser.uid).then((storedUser) => {
          if (storedUser) {
            this.user$.next(storedUser as User);
            // store.setState('user', storedUser);
          } else {
            console.log("CREATING USER", authUser);
            const user = {} as User;
            user.uid = authUser?.uid;
            user.email = authUser?.email || "";
            user.phoneNumber = authUser?.phoneNumber || "";
            user.name =
              authUser?.displayName || user?.email || user?.phoneNumber;
            user.authProvider = authUser?.providerId as any;
            this.user$.next(user as User);
            // store.setState('user', user);
          }
          scuverService
            .observeRecord("users", authUser.uid)
            ?.subscribe((user) => {
              console.log("USER SUB", "color: orange; font-size: 1em", user);
              const u =
                Array.isArray(user) && !user.length
                  ? null
                  : Array.isArray(user) && user.length
                    ? user[0]
                    : user;
              if (u) {
                this.user$.next(u as User);
                // store.setState('user', u);
              }
            });
        });
      }
    }
  }

  private trackAuthUser() {
    FirebaseAuthentication.addListener("authStateChange", (result) => {
      const authUser = result.user;
      this.userChanged(authUser);
    });
  }

  async deleteUser() {
    return auth.currentUser && auth.currentUser.delete();
  }

  registerUser(email: string, password: string) {
    return FirebaseAuthentication.createUserWithEmailAndPassword({
      email,
      password,
    });
  }

  signIn(email: string, password: string) {
    return FirebaseAuthentication.signInWithEmailAndPassword({
      email,
      password,
    });
  }

  async signInAnon() {
    const anon = await FirebaseAuthentication.signInAnonymously();
    const user = {} as User;
    user.uid = anon.user.uid;
    this.user$.next(user as User);
    // store.setState('user', user);
    return user;
  }

  signInWithPhone(phoneNumber: string) {
    return new Promise((resolve, reject) => {
      if (
        Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android"
      ) {
        FirebaseAuthentication.addListener("phoneCodeSent", (result) => {
          console.log("phoneCodeSent result", result);
          this.verificationId = result.verificationId;
        });
        FirebaseAuthentication.signInWithPhoneNumber({phoneNumber})
          .then((confirmationResult) => {
            console.log("confirmationResult", confirmationResult);
            resolve(confirmationResult);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
          size: "invisible",
          callback: (response: any) => {
            console.log("Recaptcha response", response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        });

        if (IS_DEV) {
          auth.settings.appVerificationDisabledForTesting = true;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
          .then((confirmationResult) => {
            this.confirmationResult = confirmationResult;
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.confirmationResult = confirmationResult;
            resolve(confirmationResult);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      }
    });
  }

  async insertPhoneCode(code: string) {
    try {
      if (
        Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android"
      ) {
        FirebaseAuthentication.confirmVerificationCode({
          verificationCode: code,
          verificationId: this.verificationId,
        })
          .then((confirmationResult) => {
            const user = confirmationResult.user;
            this.userChanged(user);
            console.log("user", user);
          })
          .catch((error) => {
            console.error(error);
            console.error("error", error);
          });
      } else {
        let result = await this.confirmationResult?.confirm(code);
        console.log("result", result);
        // User signed in successfully.
        const user = result.user;
        this.userChanged(user);
        console.log("user", user);
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  async signInWithGoogle() {
    console.log('signInWithGoogle');
    try {
      let result = await FirebaseAuthentication.signInWithGoogle();
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = result.credential;
      console.log("credential", credential);
      const token = credential?.accessToken;
      console.log("token", token);
      // The signed-in user info.
      const user = result.user;
      console.log("user", user);
      this.userChanged(user);
      // IdP data available using getAdditionalUserInfo(result)
      return user;
    } catch (error) {
      console.error(error);
      // Handle Errors here.
      const errorCode = error.code;
      console.log("errorCode", errorCode);
      const errorMessage = error.message;
      console.log("errorMessage", errorMessage);
      // The email of the user's account used.
      const email = error.customData.email;
      console.log("email", email);
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("credential", credential);
      return error;
    }
  }

  async signInWithApple() {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    try {
      const result = await FirebaseAuthentication.signInWithApple();
      // The signed-in user info.
      const user = result.user;
      console.log("user", user);

      // Apple credential
      const credential = result.credential;
      const accessToken = credential?.accessToken;
      console.log("accessToken", accessToken);
      const idToken = credential?.idToken;
      console.log("idToken", idToken);
      this.userChanged(user);
      return user;
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      console.log("errorCode", errorCode);
      const errorMessage = error.message;
      console.log("errorMessage", errorMessage);
      // The email of the user's account used.
      const email = error.customData.email;
      console.log("email", email);
      // The credential that was used.
      const credential_1 = OAuthProvider.credentialFromError(error);
      console.log("credential", credential_1);
      return error;
    }
  }

  async getCurrentProviderId() {
    const authUser = await this.authUser$.pipe(take(1)).toPromise();

    if (authUser && authUser.providerData[0])
      return authUser.providerData[0].providerId;
    else return null;
  }

  signOut() {
    localStorage.clear();
    this.user$.next(null);
    // store.setState('user', null);
    return auth.signOut();
  }

  async isSignedIn(): Promise<boolean> {
    const authUser = await this.authUser$.pipe(take(1)).subscribe();

    if (authUser) return true;
    else return false;
  }

  observeCurrentUser() {
    return this.user$.asObservable();
  }

  async getCurrentUserEmail() {
    const authUser = await this.authUser$.pipe(take(1)).toPromise();

    if (authUser) return authUser.email;
    else return null;
  }

  async getCurrentUser() {
    const authUser = await this.authUser$.pipe(take(1)).toPromise();

    if (authUser) return authUser;
    else return null;
  }

  sendEmailToResetPassword(email: string) {
    return FirebaseAuthentication.sendPasswordResetEmail({email});
  }
}

export default new AuthService();
