import {Order} from "../models/order";
import {OrderItem} from "../models/order-item";

class OrderHelper {

  getOrderItemTotal(orderItem: OrderItem) {
    let total = 0;
    total += orderItem.price * (orderItem.quantity || 1);
    orderItem.optionsSelected?.forEach(optionSelected => {
      total += (optionSelected.price * optionSelected.quantity) * orderItem.quantity;
    });
    if (orderItem.consumablesPrice) {
      total +=  orderItem.consumablesPrice * orderItem.quantity;
    }
    return +total || 0;
  }

  calculateSubTotal(order: Order) {
    let subTotal = 0;
    order.orderItems.forEach(orderItem => subTotal += this.getOrderItemTotal(orderItem));
    if (order.shop?.bagPrice && order.type !== 'in-restaurant') {
      subTotal += order.shop.bagPrice;
    }
    return subTotal;
  }

  calculateOrderTotal(order: Order) {

    let promo = 0;
    if (order.promo && !order.user.promoUsed) {
      if (order.promo === 'TASTICONE') {
        promo = 5;
      }
    }
    let total = this.calculateSubTotal(order);
    if (order.type === 'delivery') {
      total += order.deliveryFee;
    }
    return total - promo;
  }
}

export default new OrderHelper();

// import { take } from 'rxjs/operators';
// import { Item } from '../models/item';
// import { Option } from '../models/option';
// import { Order } from '../models/order';
// import { OrderItem } from '../models/order-item';
// import { OrderOption } from '../models/order-option';
// import { ShopHelper } from './shop-helper';
// import { MyMoment } from './time-helper';
// import { UIDGenerator } from './uid-generator';
// import {Table} from '../models/table';
// import {Address} from '../models/submodels/address';
//
// export class OrderHelper {
//
//   constructor(
//     private shopHelper: ShopHelper
//   ) {}
//
//   async buildNewOrder(table: Table) {
//     let user = await this.userService.getCurrentUser().pipe(take(1)).toPromise();
//     if (!user) {
//       user = await this.userService.setAnonUser();
//     }
//     const shop = await this.shopService.getCurrentShop().pipe(take(1)).toPromise();
//     if (!user || !shop) return;
//
//     const order = new Order();
//     order.uid = UIDGenerator.generate();
//     order.status = 'being-created';
//     order.log = [];
//     order.shop = JSON.parse(JSON.stringify(shop));
//     order.user = JSON.parse(JSON.stringify(user));
//     order.orderItems = [];
//     order.address = user && user.addresses && user.addresses.length ? user.addresses[user.addresses.length - 1] : {} as Address;
//     order.submittedAt = '';
//     order.completedAt = '';
//     if (!!table) {
//       order.type = 'in-restaurant';
//       order.table = table.tableNumber;
//     }
//
//     log('buildNewOrder order', order);
//     return order;
//   }
//
//   itemToOrderItem(item: Item, quantity: number) {
//     const orderItem = new OrderItem();
//     for (const k in item) orderItem[k] = item[k];
//     orderItem.quantity = quantity;
//
//     return orderItem;
//   }
//
//   optionToOrderOption(option: Option, quantity: number) {
//     const orderOption = new OrderOption();
//     for (const k in option) orderOption[k] = option[k];
//     orderOption.quantity = quantity;
//
//     return orderOption;
//   }
//
//   removeOrderItem(order: Order, orderItem: OrderItem) {
//     order.orderItems.findIndex((orderItem) => orderItem.uid === orderItem.uid);
//   }
//
//   getOrderSubTotal(order: Order) {
//     let subTotal = 0;
//     order.orderItems.forEach(orderItem => subTotal += this.getOrderItemTotal(orderItem, order.type === 'in-restaurant'));
//     if (order.shop.bagPrice && order.type !== 'in-restaurant') {
//       subTotal += order.shop.bagPrice;
//     }
//     return subTotal;
//   }
//
//   getOrderTotal(order: Order) {
//
//     let promo = 0;
//     if (order.promo && !order.user.promoUsed) {
//       if (order.promo === 'SCUVERONE' || order.promo === 'STUART' || order.promo === 'MESA') {
//         promo = 5;
//       }
//     }
//     let total = this.getOrderSubTotal(order);
//     if (order.type === 'delivery') {
//       total += order.deliveryFee;
//     }
//     return total - promo;
//   }
//
//   getOrderItemTotal(orderItem: OrderItem, isInRestaurant?) {
//     let total = 0;
//
//     total += orderItem.price * orderItem.quantity;
//     orderItem.optionsSelected.forEach(optionSelected => {
//       total += optionSelected.price * optionSelected.quantity;
//     });
//     if (orderItem.consumablesPrice && !isInRestaurant) {
//       total +=  orderItem.consumablesPrice * orderItem.quantity;
//     }
//     return total;
//   }
//
//   updateOrderExpectedArrivalIfNeeded(order: Order) {
//     const expectedArrivalMoment = order.arrivalExpectedAt ? MyMoment.parse(order.arrivalExpectedAt) : null;
//     const earliestArrivalTimeOfToday   = this.shopHelper.getArrivalTimesOfToday(order.shop, order.distanceInKm)[0];
//     const earliestArrivalMomentOfToday = earliestArrivalTimeOfToday ? MyMoment.todayAt(earliestArrivalTimeOfToday) : null;
//
//     if (!expectedArrivalMoment && !earliestArrivalMomentOfToday) return false;
//
//     if ( expectedArrivalMoment && !earliestArrivalMomentOfToday) {
//       order.arrivalExpectedAt = new Order().arrivalExpectedAt;
//       return true;
//     }
//
//     if (!expectedArrivalMoment &&  earliestArrivalMomentOfToday) {
//       order.arrivalExpectedAt = earliestArrivalMomentOfToday.toString();
//       return true;
//     }
//
//     // ⬇ both have a value...
//
//     if (expectedArrivalMoment.toMinutes() < earliestArrivalMomentOfToday.toMinutes()) {
//       order.arrivalExpectedAt = earliestArrivalMomentOfToday.toString();
//       return true;
//     }
//
//     return false;
//   }
//
//   areEquals(option: Option, orderOption: OrderOption) {
//     if (option.name !== orderOption.name) return false;
//     if (option.price !== orderOption.price) return false;
//     // if (option.description !== orderOption.description) return false;
//
//     return true;
//   }
//
// }
