import {EventHandler, SyntheticEvent, useRef, useState} from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import {PuffLoader} from 'react-spinners';
import placeholder from '../assets/images/placeholder.jpg';

export default function Image(props: {src: string, classes?: string, onLoad?: EventHandler<SyntheticEvent>}) {

  const ref = useRef(null);
  const [error, setError] = useState(null);

  return (
    <>
      <ProgressiveImage src={props.src} placeholder={placeholder} noRetry={true} onError={e => {
        setError(e);
      }}>
      {(src, loading) => {

        if ((!loading || error) && props.onLoad) {
          props.onLoad({} as SyntheticEvent);
        }

        return loading ? error ?
          <img src={placeholder} alt="a placeholder image" className={`${props.classes} object-cover`}/> :
          <div ref={ref} className={`flex justify-center ${props.classes}`}>
            <PuffLoader color="#209c95" size={ref.current?.clientHeight}/>
          </div> :
          <img src={src} alt="an image" className={`${props.classes}`}/>
      }}
      </ProgressiveImage>
    </>
  );
}
