import {MouseEventHandler} from 'react';

const Button = (props: {
  onClick: MouseEventHandler
  type?: 'square' | 'round'
  size?: 'small' | 'normal' | 'big'
  textColor?: string
  backgroundColor?: string
  icon?: string
  iconImage?: string
  text?: string,
  classes?: string
  disabled?: boolean
}) => {

  return (
    <button disabled={props.disabled} onClick={props.onClick} className={`
      flex w-full justify-center text-center cursor-pointer font-semibold hover:opacity-80
      ${props.type === 'square' ? 'rounded-md' : 'rounded-full'}
      ${props.size === 'big' ? 'px-12 py-4 text-3xl' : props.size === 'small' ? 'px-2 py-0 text-sm font-bold' : 'px-6 py-2 text-base'} 
      ${props.textColor || 'text-white'} ${props.backgroundColor || 'bg-primary-600'}
      ${props.classes}
      ${props.disabled ? '!bg-gray-300 hover:opacity-100 cursor-auto' : ''}
    `}>
      {props.iconImage ? <img alt="avatar" src={props.iconImage} className="inline w-6 h-6"/> : props.icon ? <div>ICON HERE</div> : ''}
      <span className={`inline ${props.icon || props.iconImage ? 'ml-2' : ''} relative bold`}>{props.text}</span>
    </button>
  );
}
export default Button;
