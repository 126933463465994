import {useTranslation} from "react-i18next";
import background from "../assets/images/background.jpg";
import logoAndText from "../assets/images/logo_and_text_white.svg";
import logo from "../assets/images/logo_white.svg";
import logoText from "../assets/images/logo_text_white.svg";
import {MapPinIcon} from "@heroicons/react/20/solid";
import {useEffect, useState} from "react";
import AddressSelection from "../components/AddressSelection.tsx";
import Input from "../components/Input.tsx";
import authService from "../services/auth.service.ts";
import {useNavigate} from "react-router-dom";
import store from "../state-store.tsx";
import {Address} from "../models/submodels/address.ts";
import addressService from "../services/address.service.ts";
// import {UIDGenerator} from '../utils/uid-generator.ts';
export const Welcome = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [address, setAddress] = useState({} as Address);
  const [displayAddresses, setDisplayAddresses] = useState(false);

  // const [displaySignIn, setDisplaySignIn] = useState(false);

  function startAnon() {
    authService.signInAnon().then(() => {
      navigate("/home");
    });
  }

  useEffect(() => {
    const pathname = location.pathname?.substring(
      location.pathname.lastIndexOf("/") + 1,
    );
    if (pathname && pathname === "contacts") {
      store.setState("contactsOpen", true);
    }
    addressService.observeCurrentAddress().subscribe((a) => setAddress(a));
  });

  // useEffect(() => {
  //
  //   fetch("https://api.ipify.org?format=json")
  //     .then(response => {
  //       return response.json();
  //     })
  //     .then((responseData: any) => {
  //       console.log(responseData.ip)
  //       const d = new Date();
  //       const utcDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(), d.getUTCMilliseconds());
  //       const form = new FormData();
  //       const data =  [
  //           {
  //             "event_name": "ViewContent",
  //             "event_time": Math.floor(utcDate.getTime() / 1000),
  //             "action_source": "website",
  //             "event_id": UIDGenerator.generate(),
  //             "user_data": {
  //               "em": [
  //                 "5fb924ba4a994b35562cb977586edc2d28e45b8131ceef16393287babc7e861b"
  //               ],
  //               "ph": [
  //                 "26d564c710d984f058f0b4d8cb7bcd578a84c6c866a9dd036bf2a5af0e0e56f5"
  //               ],
  //               "client_ip_address": responseData.ip,
  //               "client_user_agent": window.navigator.userAgent
  //             }
  //           }
  //         ]
  //       form.append('data', JSON.stringify(data));
  //       form.append('access_token', 'EAADwOvHcg8UBOxY35eUF55LjuRg4591ji8DIgxpZCU94GbiUAEfpmWtxNyjJwwb4tT2DYNCZC5r2jFDPtqOADNUKMwgB05dspBa4kxEFnwFZBG43KZAWTpMc3EVvlv3rOAMF2pKvEbW4ZAZBWRfsbYEYQCjBhWbLQdRcg61hqtEOPqhGyyvZCZALZATlt3H2o90ZAgEgZDZD\n');
  //
  //       fetch('https://graph.facebook.com/v18.0/851539449933922/events', {
  //         method: 'POST',
  //         body: form
  //       }).then(r => console.log(r)).catch(e => console.error(e))
  //     })
  //     .catch(err => console.log(err))
  // }, [])

  return (
    <>
      {/*<span className={'absolute bottom-20 left-0 text-4xl text-white z-50'}>displayAddresses: {displayAddresses.toString()}</span>*/}
      <div
        style={{backgroundImage: `url(${background})`}}
        className="bg-no-repeat bg-cover bg-center h-[100vh] w-[100vw] z-0 pt-2 px-8 md:p-16"
      >
        <div className="absolute w-full h-full bg-black opacity-70 top-0 left-0 z-0"></div>
        <header className="flex w-full md:mb-28 justify-center md:justify-between z-20 items-center">
          <div className="z-20 hidden md:block">
            <img src={logoAndText} className="w-42 h-14"/>
          </div>
          <div className="z-20 md:hidden flex flex-col space-y-6 items-center mt-20">
            <img src={logo} className="w-5/12 logo"/>
            <img src={logoText} className="w-10/12"/>
          </div>
          {/*<div className="flex z-20 space-x-6 absolute bottom-4 md:relative md:bottom-auto text-sm md:text-base">*/}
          {/*  <Button*/}
          {/*    onClick={() => setDisplaySignIn(true)}*/}
          {/*    iconImage={avatar}*/}
          {/*    text={t("Login") + '(opcional)'}*/}
          {/*    backgroundColor="bg-white"*/}
          {/*    textColor={"text-black"}*/}
          {/*  />*/}
          {/*  <Button*/}
          {/*    onClick={() => setDisplaySignIn(true)}*/}
          {/*    text={t("Sign up") + '(opcional)'}*/}
          {/*  />*/}
          {/*</div>*/}
        </header>
        <main className="flex z-20 w-full">
          <div className="flex flex-col z-20 w-full">
            <div className="md:flex md:flex-col mt-4 text-center md:text-justify mb-8">
              <span className="text-lg md:text-7xl z-20 font-light md:font-thin">
                Where Food is Fan...
              </span>
              <span className="text-lg md:text-7xl z-20 font-light md:font-thin">
                wait for it...
              </span>
              <span className="hidden md:block text-lg md:text-7xl z-20 font-semibold font-bold font-signika">
                tastic!
              </span>
            </div>
            {/*<div className="flex flex-col z-20 space-y-8 mt-8 w-full items-center md:items-start">*/}
            {/*  <div*/}
            {/*    className="flex flex-col space-y-6 md:space-y-0 md:flex-row w-full md:w-10/12 md:justify-between md:space-x-6">*/}
            <div
              className="flex flex-col space-y-6 md:space-y-0 md:flex-row w-full md:w-10/12 md:justify-between md:space-x-6">
              <Input
                onClick={() => {
                  setDisplayAddresses(true);
                }}
                onChange={() => {
                }}
                type={"text"}
                initialValue={address?.addressLine1}
                placeholder={t("Enter delivery address")}
                classes={"md:w-8/12"}
                icon={
                  <MapPinIcon className="absolute top-4 md:ml-2 w-8 md:w-10 text-black"/>
                }
              />
              <button
                onClick={startAnon}
                className="md:w-4/12 p-4 md:p-7 bg-primary-600 text-white rounded-full border-0 cursor-pointer shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-800"
              >
                  <span className="font-bold text-xl">
                    {t("Search")}
                  </span>
              </button>
            </div>
            {/*</div>*/}
            {/*<div className="text-sm md:text-lg text-center">*/}
            {/*  <span*/}
            {/*    onClick={() => setDisplaySignIn(true)}*/}
            {/*    className="underline font-bold cursor-pointer text-gray-100 mt-8"*/}
            {/*  >*/}
            {/*    {t("Login / Sign Up")} (opcional)*/}
            {/*  </span>*/}
            {/*  <span className={"text-gray-300"}>*/}
            {/*    {" "}*/}
            {/*    {t("to access promotions")}*/}
            {/*    {", "}*/}
            {/*    {t("saved addresses")}*/}
            {/*    {", "}*/}
            {/*    {t("payments")}*/}
            {/*    {", "}*/}
            {/*    {t("order history")} {t("and more")}.*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
          <AddressSelection
            currentAddress={address}
            show={displayAddresses}
            onClose={() => setDisplayAddresses(false)}
          />
          {/*<SignIn*/}
          {/*  show={displaySignIn}*/}
          {/*  onClose={() => setDisplaySignIn(false)}*/}
          {/*/>*/}
        </main>
      </div>
    </>
  );
};
