import logo from '../assets/images/logo_white.svg';
import {PulseLoader} from 'react-spinners';
// import logoText from '../assets/images/logo_text.svg';
// import {useTranslation} from 'react-i18next';
// import motoca from '../assets/images/motoca.svg';
// import casa from '../assets/images/casa.svg';
// import estrada from '../assets/images/estrada.svg';

export const Loading = (props: {show: boolean}) => {

  // const {t} = useTranslation();

  return (
    <>
      {/*<div className="fixed inset-0 bg-white bg-opacity-50 z-10" />*/}
      <div className={`absolute top-0 space-y-6 left-0 z-50 flex flex-col justify-center items-center bg-opacity-75 bg-black h-[100vh] w-[100vw] ${props.show ? '' : 'hidden'}`}>
        <div className="flex flex-col  items-center mt-2">
          <img src={logo} className="w-32 logo opacity-100 rotate hidden md:block"/>
          <img src={logo} className="w-24 logo opacity-100 md:hidden"/>
        </div>
        <div className={'md:hidden'}>
          <PulseLoader color="#fff"/>
        </div>
        {/*<div className={'text-center text-3xl font-signika'}>{t('Wait for it')}...</div>*/}
        {/*<div className={'flex flex-col'}>*/}
        {/*  <div className={'flex'}>*/}
        {/*    <img src={motoca} className="w-6/12"/>*/}
        {/*    <img src={casa} className="w-6/12"/>*/}
        {/*  </div>*/}
        {/*  <img src={estrada} className="w-8/12"/>*/}
        {/*</div>*/}
      </div>
    </>
  );
};
