import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header.tsx";
import { Category } from "../models/category.ts";
import { Shop } from "../models/shop.ts";
import scuverService from "../services/scuver.service.ts";
import Footer from "../components/Footer.tsx";
import ShopSubHeader from "../components/ShopSubHeader.tsx";
import { scrollToTargetAdjusted } from "../utils/general-utils.ts";
import { ChevronDoubleUpIcon } from "@heroicons/react/24/solid";
import Image from "../components/Image.tsx";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { User } from "../models/user.ts";
import authService from "../services/auth.service.ts";
import store from "../state-store.tsx";

export const ShopPage = () => {
  const [user, setUser] = useState<User>({} as User);
  const [shop, setShop] = useState<Shop>({} as Shop);
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [categoryName, setCategoryName] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    init();
    store.observeState("shop")?.subscribe((s) => {
      s && s.uid && (!shop || s.uid !== shop?.uid) ? setShop(s) : "";
    });
  }, []);

  async function init() {
    authService.observeCurrentUser().subscribe((u) => {
      setUser(u);
    });
    const pathio = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1,
    );
    if (pathio && pathio !== "shop" && pathio.substring(0, 1) !== "9") {
      const chosenShop = await scuverService.getRecord("shops", pathio);
      if (!chosenShop) {
        const shops = await scuverService.getCollection("shops");
        for (const shp of shops) {
          if (
            pathio === shp.name?.toLowerCase().replace(new RegExp(" ", "g"), "")
          ) {
            store.setState("shop", shp);
            scuverService
              .getRecordsByProperty("shop-categories", "shopId", "==", shp.uid)
              .then((storedCategories) => {
                setCategories(
                  storedCategories.sort((a, b) =>
                    (a.priority || 99999) > (b.priority || 99999) ? 1 : -1,
                  ),
                );
              });
            break;
          }
        }
      } else {
        store.setState("shop", chosenShop);
        if (!user) {
          authService.signInAnon();
        }
        scuverService
          .getRecordsByProperty(
            "shop-categories",
            "shopId",
            "==",
            chosenShop.uid,
          )
          .then((storedCategories) => {
            setCategories(
              storedCategories.sort((a, b) =>
                (a.priority || 99999) > (b.priority || 99999) ? 1 : -1,
              ),
            );
          });
      }
    } else if (pathio && pathio.substring(0, 1) === "9") {
      console.log("Should navigate to rewards.");
      navigate("/rewards/" + pathio);
    } else if (shop) {
      scuverService
        .getRecordsByProperty("shop-categories", "shopId", "==", shop.uid)
        .then((storedCategories) => {
          setCategories(
            storedCategories.sort((a, b) =>
              (a.priority || 99999) > (b.priority || 99999) ? 1 : -1,
            ),
          );
        });
    } else {
      navigate("/");
    }
  }

  function displayItem(item, category) {
    //console.log('category.weekday', category.weekday)
    const weekday = new Date().getDay() - 1;
    return (
      item &&
      (category.weekday === -1 || category.weekday === weekday) &&
      item.available &&
      !item.excludeInDelivery &&
      item.price &&
      item.price > 0
    );
  }

  useEffect(() => {
    setCategoryName(categories[0]?.name);
  }, [categories]);

  const chooseCategory = (category) => {
    setCategoryName(category.name);
    scrollToTargetAdjusted(document.getElementById(category.uid), 80);
  };

  const chooseItem = (item) => {
    navigate(`/item/${item.uid}`);
  };

  return (
    <div className={"min-h-screen"}>
      <Header />
      <div className="hidden md:block">
        <ShopSubHeader />
      </div>
      <div
        className="md:m-4 text-primary-500 flex cursor-pointer"
        onClick={() => navigate("/home")}
      >
        <ChevronLeftIcon className="w-6" />
        <span>{t("Back")}</span>
      </div>
      <main className="flex  p-3 text-gray-800">
        <div className="flex flex-col space-y-4">
          <div className={"mt-2 xl:text-center"}>
            {categories && categories.length ? (
              <div
                className={
                  "md:space-x-4 text-center xl:flex categories overflow-auto max-w-[94vw] whitespace-nowrap py-4"
                }
              >
                {categories.map((category, it) => (
                  <div
                    key={category.uid}
                    className={`${it > 0 ? "ml-1" : ""} inline-block text-center cursor-pointer hover:opacity-90 rounded-full text-white px-2 border border-gray-100 ${category.name === categoryName ? "bg-primary-600 text-white" : ""}`}
                    onClick={(e) => {
                      e.preventDefault();
                      chooseCategory(category);
                    }}
                  >
                    <span
                      className={`font-semibold text-base text-primary-600 ${category.name === categoryName ? "text-white" : ""}`}
                    >
                      {category.name}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <hr />
          <div className="flex flex-col">
            {categories && categories.length
              ? categories.map((category, it) => (
                  <div key={category.uid} id={category.uid}>
                    <div className="flex items-center">
                      <h3 className="font-semibold lg:text-2xl mb-4">
                        {category.name}
                      </h3>
                      {it > 1 ? (
                        <ChevronDoubleUpIcon
                          className={
                            "text-base text-primary-600 h-6 w-6 cursor-pointer relative bottom-2 ml-4"
                          }
                          onClick={() =>
                            scrollTo({ top: 0, behavior: "smooth" })
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={
                        "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 3xl:grid-cols-8 flex-wrap w-[94vw] md:w-full justify-start"
                      }
                    >
                      {category &&
                      category.items &&
                      category.items.length &&
                      category.items.filter((itm) => displayItem(itm, category))
                        .length ? (
                        category.items
                          .filter((itm) => displayItem(itm, category))
                          .map(
                            (item) =>
                              displayItem(item, category) && (
                                <div
                                  className={
                                    "flex flex-col mr-0 md:mr-4 mb-6 cursor-pointer"
                                  }
                                  key={item.uid}
                                  onClick={() => chooseItem(item)}
                                >
                                  <div className={"rounded-2xl"}>
                                    <Image
                                      src={item.photoUrl}
                                      classes="border border-[#ccc] w-full h-36 rounded-2xl object-cover"
                                    />
                                  </div>
                                  <div
                                    className={
                                      "flex justify-between items-top px-1"
                                    }
                                  >
                                    <div
                                      className={"flex flex-col mt-3 w-10/12"}
                                    >
                                      <span
                                        className={
                                          "font-normal text-sm md:text-sm truncate"
                                        }
                                      >
                                        {item.name}
                                      </span>
                                      <div className={"flex text-base"}>
                                        <span
                                          className={"text-gray-400 text-xs"}
                                        >
                                          {item.description}
                                        </span>
                                      </div>
                                    </div>
                                    <span className="text-base mr-1 mt-2 font-bold">
                                      €{item.price}
                                    </span>
                                  </div>
                                </div>
                              ),
                          )
                      ) : (
                        <div className={"w-full"}>
                          <span className={"text-xl text-primary-500"}>
                            {t("No items for this category.")}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              : t("No categories for this shop.")}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
