import {API_URL} from '../constants';
import {Order} from '../models/order';
import httpHelper from '../utils/http-helper';


class MyposService {

  payByToken(order: Order, token: string) {
    const url  = `${API_URL}/MyPosPayment`;
    return httpHelper.post(url, {order, user: order.user, token});
  }

  pay(amount = 0, orderID = 'NAO_HA_ORDER_ID', userID = 'NAO_HA_USER_ID') {
    window.open(`https://scuver.pt/card-payment?amount=${amount}&orderID=${orderID}&userID=${userID}&isTastic=true`, '_blank');
  }

  payWallet(amount = 0, orderID = 'NAO_HA_ORDER_ID', userID = 'NAO_HA_USER_ID') {
    window.open(`https://scuver.pt/card-payment?isWallet=true&amount=${amount}&orderID=${orderID}&userID=${userID}`, '_blank');
  }
}

export default new MyposService();
