import i18n from "i18next";
import store from "../state-store";
import Dialog from "./Dialog";
import { useState } from "react";

export default function Privacy() {
  const lang =
    i18n.language ||
    (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
    "en";
  const [open, setOpen] = useState(false);
  store.observeState("privacyOpen")?.subscribe((v) => v && setOpen(true));

  return (
    <Dialog
      show={open || false}
      onClose={() => store.setState("privacyOpen", false)}
      classes="bg-white overflow-y-auto overflow-x-hidden max-h-[96vh]"
    >
      {lang === "en" ? (
        <div className="bg-white text-gray-700 overflow-auto">
          <h1 className="text-xl font-bold">
            Privacy Policy for Modern Marathon Lda.
          </h1>

          <p>
            At Tastic, accessible from https://tastic.pt, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by Tastic and how we use it.
          </p>

          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Tastic. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>

          <h2>Consent</h2>

          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>

          <h2>Information we collect</h2>

          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>

          <h2>How we use your information</h2>

          <p>
            We use the information we collect in various ways, including to:
          </p>

          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>

          <h2>Log Files</h2>

          <p>
            Tastic follows a standard procedure of using log files. These files
            log visitors when they visit websites. All hosting companies do this
            and a part of hosting services' analytics. The information collected
            by log files include internet protocol (IP) addresses, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number of clicks. These are not linked to
            any information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the site,
            tracking users' movement on the website, and gathering demographic
            information.
          </p>

          <h2>Advertising Partners Privacy Policies</h2>

          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Tastic.
          </p>

          <p>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on Tastic, which are
            sent directly to users' browser. They automatically receive your IP
            address when this occurs. These technologies are used to measure the
            effectiveness of their advertising campaigns and/or to personalize
            the advertising content that you see on websites that you visit.
          </p>

          <p>
            Note that Tastic has no access to or control over these cookies that
            are used by third-party advertisers.
          </p>

          <h2>Third Party Privacy Policies</h2>

          <p>
            Tastic's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.{" "}
          </p>

          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>

          <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

          <p>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>
          <p>
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p>
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p>
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2>GDPR Data Protection Rights</h2>

          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
          <p>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
          <p>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
          <p>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2>Children's Information</h2>

          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>

          <p>
            Tastic does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>

          <h2>Changes to This Privacy Policy</h2>

          <p>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </p>

          <h2>Contact Us</h2>

          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us.
          </p>
        </div>
      ) : (
        <div className="bg-white text-gray-700 overflow-auto">
          <h1 className="text-xl font-bold">
            Política de Privacidade da Modern Marathon Lda.
          </h1>

          <p>
            Na Tastic, acessível em https://tastic.pt, uma das nossas principais
            prioridades é a privacidade dos nossos visitantes. Este documento da
            Política de Privacidade contém tipos de informações que são
            coletadas e registradas pela Tastic e como as usamos.
          </p>

          <p>
            Se você tiver dúvidas adicionais ou precisar de mais informações
            sobre nossa Política de Privacidade, não hesite em nos contatar.
          </p>

          <p>
            Esta Política de Privacidade se aplica apenas às nossas atividades
            online e é válida para os visitantes do nosso site no que diz
            respeito às informações que eles compartilharam e/ou coletaram no
            Tastic. Esta política não se aplica a nenhuma informação coletada
            offline ou por meio de outros canais que não este site.
          </p>

          <h2>Consentimento</h2>

          <p>
            Ao usar nosso site, você concorda com nossa Política de Privacidade
            e concorda com seus termos.
          </p>

          <h2>Informações que coletamos</h2>

          <p>
            As informações pessoais que você deve fornecer e os motivos pelos
            quais você deve fornecê-las serão esclarecidas no momento em que
            solicitarmos que você forneça suas informações pessoais.
          </p>
          <p>
            Se você entrar em contato conosco diretamente, podemos receber
            informações adicionais sobre você, como seu nome, endereço de
            e-mail, número de telefone, o conteúdo da mensagem e/ou anexos que
            você pode nos enviar e qualquer outra informação que você queira
            fornecer.
          </p>
          <p>
            Quando você se registra em uma conta, podemos solicitar suas
            informações de contato, incluindo itens como nome, nome da empresa,
            endereço, endereço de e-mail e número de telefone.
          </p>

          <h2>Como usamos suas informações</h2>

          <p>
            Usamos as informações que coletamos de várias maneiras, inclusive
            para:
          </p>

          <ul>
            <li>Fornecer, operar e manter nosso site</li>
            <li>Melhorar, personalizar e expandir nosso site</li>
            <li>Entenda e analise como você usa nosso site</li>
            <li>
              Desenvolver novos produtos, serviços, recursos e funcionalidades
            </li>
            <li>
              Comunicar com você, diretamente ou por meio de um de nossos
              parceiros, inclusive para atendimento ao cliente, para fornecer
              atualizações e outras informações relacionadas ao site e para fins
              promocionais e de marketing
            </li>
            <li>Enviar e-mails para você</li>
            <li>Encontre e evite fraudes</li>
          </ul>

          <h2>Arquivos de registro</h2>

          <p>
            Tastic segue um procedimento padrão de uso de arquivos de log. Esses
            arquivos registram os visitantes quando eles visitam sites. Todas as
            empresas de hospedagem fazem isso e fazem parte da análise dos
            serviços de hospedagem. As informações coletadas pelos arquivos de
            log incluem endereços de protocolo da Internet (IP), tipo de
            navegador, provedor de serviços de Internet (ISP), carimbo de data e
            hora, páginas de referência/saída e possivelmente o número de
            cliques. Estes não estão vinculados a nenhuma informação que seja
            pessoalmente identificável. O objetivo das informações é analisar
            tendências, administrar o site, rastrear o movimento dos usuários no
            site e coletar informações demográficas.
          </p>

          <h2>Políticas de privacidade de parceiros de publicidade</h2>

          <p>
            Você pode consultar esta lista para encontrar a Política de
            Privacidade de cada um dos parceiros de publicidade da Tastic.
          </p>

          <p>
            Servidores de anúncios ou redes de anúncios de terceiros usam
            tecnologias como cookies, JavaScript ou Web Beacons que são usados
            em seus respectivos anúncios e links que aparecem no Tastic, que são
            enviados diretamente ao navegador dos usuários. Eles recebem
            automaticamente seu endereço IP quando isso ocorre. Essas
            tecnologias são usadas para medir a eficácia de suas campanhas
            publicitárias e/ou para personalizar o conteúdo publicitário que
            você vê nos sites que visita.
          </p>

          <p>
            Observe que a Tastic não tem acesso ou controle sobre esses cookies
            usados por anunciantes terceirizados.
          </p>

          <h2>Políticas de privacidade de terceiros</h2>

          <p>
            A Política de Privacidade da Tastic não se aplica a outros
            anunciantes ou sites. Assim, aconselhamos a consulta das respectivas
            Políticas de Privacidade desses servidores de anúncios de terceiros
            para informações mais detalhadas. Pode incluir suas práticas e
            instruções sobre como cancelar certas opções.{" "}
          </p>

          <p>
            Você pode optar por desativar os cookies por meio das opções
            individuais do seu navegador. Para saber informações mais detalhadas
            sobre o gerenciamento de cookies com navegadores específicos, elas
            podem ser encontradas nos respectivos sites dos navegadores.
          </p>

          <h2>
            Direitos de privacidade da CCPA (não venda minhas informações
            pessoais)
          </h2>

          <p>
            De acordo com a CCPA, entre outros direitos, os consumidores da
            Califórnia têm o direito de:
          </p>
          <p>
            Solicite que uma empresa que coleta os dados pessoais de um
            consumidor divulgue as categorias e partes específicas de dados
            pessoais que uma empresa coletou sobre os consumidores.
          </p>
          <p>
            Solicite que uma empresa exclua todos os dados pessoais sobre o
            consumidor que uma empresa coletou.
          </p>
          <p>
            Exigir que uma empresa que vende os dados pessoais de um consumidor
            não venda os dados pessoais do consumidor.
          </p>
          <p>
            Se fizer um pedido, temos um mês para lhe responder. Se você deseja
            exercer algum desses direitos, entre em contato conosco.
          </p>

          <h2>Direitos de proteção de dados GDPR</h2>

          <p>
            Gostaríamos de garantir que você esteja totalmente ciente de todos
            os seus direitos de proteção de dados. Todo usuário tem direito ao
            seguinte:
          </p>
          <p>
            Direito de acesso – Tem o direito de solicitar cópias dos seus dados
            pessoais. Podemos cobrar uma pequena taxa por este serviço.
          </p>
          <p>
            Direito de retificação – Você tem o direito de solicitar que
            corrijamos qualquer informação que considere imprecisa. Você também
            tem o direito de solicitar que completemos as informações que
            acredita estarem incompletas.
          </p>
          <p>
            O direito de apagar – Você tem o direito de solicitar que apaguemos
            seus dados pessoais, sob certas condições.
          </p>
          <p>
            O direito de restringir o processamento – Você tem o direito de
            solicitar que restrinjamos o processamento de seus dados pessoais,
            sob certas condições.
          </p>
          <p>
            O direito de se opor ao processamento – Você tem o direito de se
            opor ao processamento de seus dados pessoais, sob certas condições.
          </p>
          <p>
            O direito à portabilidade de dados – Você tem o direito de solicitar
            que transfiramos os dados que coletamos para outra organização, ou
            diretamente para você, sob certas condições.
          </p>
          <p>
            Se fizer um pedido, temos um mês para lhe responder. Se você deseja
            exercer algum desses direitos, entre em contato conosco.
          </p>

          <h2>Informações para crianças</h2>

          <p>
            Outra parte de nossa prioridade é adicionar proteção para crianças
            durante o uso da Internet. Incentivamos os pais e responsáveis a
            observar, participar e/ou monitorar e orientar suas atividades
            online.
          </p>

          <p>
            A Tastic não coleta intencionalmente nenhuma informação pessoal
            identificável de crianças menores de 13 anos. Se você acha que seu
            filho forneceu esse tipo de informação em nosso site, recomendamos
            que você entre em contato conosco imediatamente e faremos o possível
            para remover imediatamente essas informações de nossos registros.
          </p>

          <h2>Mudanças nesta Política de Privacidade</h2>

          <p>
            Podemos atualizar nossa Política de Privacidade de tempos em tempos.
            Assim, aconselhamos que você revise esta página periodicamente para
            quaisquer alterações. Iremos notificá-lo sobre quaisquer alterações
            publicando a nova Política de Privacidade nesta página. Essas
            alterações entram em vigor imediatamente após serem publicadas nesta
            página.
          </p>

          <h2>Fale conosco</h2>

          <p>
            Se você tiver alguma dúvida ou sugestão sobre nossa Política de
            Privacidade, não hesite em nos contatar.
          </p>
        </div>
      )}
    </Dialog>
  );
}
