import {Popover, Transition} from "@headlessui/react";
import {ArchiveBoxIcon, ArrowLeftOnRectangleIcon, ChevronRightIcon,} from "@heroicons/react/20/solid";
import {Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import authService from "../services/auth.service.ts";
import {useNavigate} from "react-router-dom";
import store from "../state-store.tsx";
import {User} from "../models/user.ts";
import UserProfile from "./UserProfile.tsx";
import SignIn from './SignIn.tsx';

export default function UserMenu() {
  const {t} = useTranslation();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [user, setUser] = useState<User>({} as User);
  const [displaySignIn, setDisplaySignIn] = useState(false);
  const thisRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    store
      .observeState("userMenuOpen")
      ?.subscribe((v) => v && setUserMenuOpen(true));
  }, []);

  useEffect(() => {
    if (userMenuOpen) {
      document.addEventListener("click", handleOutsideClick, {once: true});
    }
    authService.observeCurrentUser().subscribe((u) => setUser(u));
  }, [userMenuOpen]);

  function handleOutsideClick(e: MouseEvent) {
    if (
      thisRef.current &&
      !(thisRef.current as HTMLElement).contains(e.target as HTMLElement)
    ) {
      store.setState("userMenuOpen", false);
    }
  }

  function logout(e: MouseEvent) {
    e.preventDefault();
    authService.signOut().then(() => {
      store.setState("userMenuOpen", false);
      localStorage.clear();
      navigate("/");
      window.location.reload();
      window.location.href = "/";
    });
  }

  return (
    <>
      <Popover
        className={`relative ${userMenuOpen ? "flex" : "none"}`}
        ref={thisRef}
      >
        <Transition
          appear
          show={userMenuOpen}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 mt-2 flex px-4 -right-4">
            <div
              className="w-[20em] flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
              <div>
                <p
                  onClick={() => {
                    store.setState("profileOpen", true);
                  }}
                  className={
                    "flex p-2 w-full text-primary-500 justify-center rounded font-bold border-b cursor-pointer"
                  }
                >
                  {user?.name || user?.email || user?.phoneNumber || "Conta"}
                  <ChevronRightIcon className={"w-6 h-6 ml-2"}/>
                </p>
                {!user || !user.uid || (!user.email && !user.phoneNumber) ? <div
                  className="grid grid-cols-1 divide-x divide-gray-900/5 bg-gray-50"
                  onClick={() => setDisplaySignIn(true)}
                >
                  <a
                    key={"item.name"}
                    href={"#"}
                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100 hover:text-primary-600"
                  >
                    {/*<ArrowRightOnRectangleIcon*/}
                    {/*  className="h-5 w-5 flex-none text-gray-400"*/}
                    {/*  aria-hidden="true"*/}
                    {/*/>*/}
                    {t("Login / Sign Up")}
                  </a>
                </div> : <></>
                }
                <div className="p-4">
                  <div
                    key={"item.name"}
                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                  >
                    <div
                      className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <ArchiveBoxIcon
                        className="h-6 w-6 text-gray-600 group-hover:text-primary-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div>
                      <a
                        href={"/order-history"}
                        className="font-semibold text-gray-900 hover:text-primary-600"
                      >
                        {t("Order History")}
                        <span className="absolute inset-0"/>
                      </a>
                      <p className="mt-1 text-gray-600">
                        {t("Previous Orders")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="grid grid-cols-1 divide-x divide-gray-900/5 bg-gray-50"
                onClick={(e) => logout(e as any)}
              >
                <a
                  key={"item.name"}
                  href={"#"}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100 hover:text-primary-600"
                >
                  <ArrowLeftOnRectangleIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                  {t("Logout")}
                </a>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <UserProfile onClose={() => setUserMenuOpen(false)}/>
      <SignIn
        show={displaySignIn}
        onClose={() => setDisplaySignIn(false)}
      />
    </>
  );
}
