import Dialog from "./Dialog.tsx";
import store from "../state-store.tsx";
import {useTranslation} from "react-i18next";
import {User} from "../models/user.ts";
import scuverService from "../services/scuver.service.ts";
import authService from "../services/auth.service.ts";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function UserProfile(props) {
    const [showDialog, setShowDialog] = useState(false);
    const [user, setUser] = useState<User>({} as User);
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        store.setState("userMenuOpen", false);
        authService.observeCurrentUser().subscribe((usr) => {
            setUser(usr);
        });
        store
            .observeState("profileOpen")
            .subscribe((v) => v && setShowDialog(true));
    }, []);

    useEffect(() => {
        if (!showDialog) {
            store.setState('profileOpen', false);
            if (props.onClose) {
                props.onClose();
            }
        }
    }, [showDialog]);

    function save() {
        console.log("Saving user", user);
        scuverService.addOrUpdateRecord("users", {...user}).then(() => {
            setShowDialog(false);
        });
    }

    function deleteAccount() {
        if (window.confirm(t("Really Delete Account?"))) {
            scuverService.removeRecord("users", user.uid).then(() => {
                authService.deleteUser().then(() => {
                    store.setState("userMenuOpen", false);
                    localStorage.clear();
                    navigate("/");
                    window.location.reload();
                    window.location.href = "/";
                });
            });
        }
    }

    return (
        <Dialog
            key={"profile"}
            show={showDialog}
            classes={"bg-gray-700"}
            onClose={() => {
                setShowDialog(false);
            }}
        >
            <div className="divide-y divide-white/5">
                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div className="md:col-span-2">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium leading-6 text-white"
                                >
                                    {t("Name")}
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={user?.name}
                                        onChange={(e) => setUser({...user, name: e.target.value})}
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="name"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-white"
                                >
                                    {t("Email address")}
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={user?.email}
                                        onChange={(e) =>
                                            setUser({...user, email: e.target.value})
                                        }
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 flex">
                            <button
                                onClick={save}
                                type="submit"
                                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                {t("Save")}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-white">
                            {t("Delete account")}
                        </h2>
                    </div>

                    <div className="flex items-start md:col-span-2">
                        <button
                            onClick={deleteAccount}
                            type="submit"
                            className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                        >
                            {t("Yes, delete my account")}
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
