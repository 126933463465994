import {API_URL} from "../constants";
import {User} from "../models/user";
import httpHelper from "../utils/http-helper";

class EasypayService {

  createEasypayPayment(user: User, orderId: string, amount: number, method: 'mb'|'mbw'|'card', type: 'createPayment' | 'createFrequentPayment' = 'createPayment', paymentType: 'Wallet' | 'Tip' | 'Order' = 'Order'): Promise<any> {
    const url  = `${API_URL}/${type}`;
    const body = this.buildRequestBody(user, orderId, amount, method, paymentType);
    return httpHelper.post(url, body);
  }

  private buildRequestBody(user: User, orderId: string, amount: number, method: string, type) {
    return {
      customer: {
        uid: user.uid,
        key: user.uid,
        name: user.name || 'Consumidor Final',
        email: user.email || 'scuverpt@gmail.com',
        phone: user?.phoneNumber || '+351000000000',
        fiscalNumber: user.fiscalNumber || ''
      },
      orderId,
      amount,
      method,
      type
    };
  }
}

export default new EasypayService();
