import { useTranslation } from "react-i18next";
import logoAndText from "../assets/images/logo_and_text_white.svg";
import store from "../state-store";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="text-center mx-4 rounded-t-3xl bg-gray-800 text-white">
      <div className="inline-block m-2">
        <img src={logoAndText} className="inline-block w-[6rem] my-2" />
        <div className="flex space-x-4 m-2">
          <span
            className="cursor-pointer"
            onClick={() => store.setState("privacyOpen", true)}
          >
            {t("Privacy Policy")}
          </span>
          <span
            className="cursor-pointer"
            onClick={() => store.setState("contactsOpen", true)}
          >
            {t("Contacts")}
          </span>
          <span
            className="cursor-pointer"
            onClick={() => store.setState("contactsOpen", true)}
          >
            {t("Help")}
          </span>
        </div>
      </div>
    </footer>
  );
}
