import {FocusEventHandler, MouseEventHandler, ReactNode, useState} from 'react';

export default function Select(props: {
  onChange?: any,
  onClick?: MouseEventHandler,
  onFocus?: FocusEventHandler,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEnter?: Function,
  id?: string,
  name?: string,
  initialValue?: any,
  options: Array<any>,
  placeholder?:any,
  classes?: string,
  icon?: ReactNode
}) {

  const [value, setValue] = useState(props.initialValue);

  console.log('props', props);

  return (

    <div onClick={props.onClick} className={"bg-transparent " + props.classes}>
      <select
        id={props.id}
        name={props?.name}
        value={value}
        onChange={e => {
          setValue(e.target.value);
          if (props.onChange) {
            props.onChange(e.target.value);
          }
        }}
        onClick={props.onClick}
        onFocus={props.onFocus}
        placeholder={props.placeholder}
        onKeyDown={e => {
          if (e.key === 'Enter' && props.onEnter) {
            props.onEnter();
          }
        }}
        className="block max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
      >
        {props.options?.map(option =>
          <option className={`bg-cover`} key={option.uid || option.value || option} value={option}>{option.label || option}</option>
        )}
      </select>
    </div>
  );
}
