import {Coordinates} from '../models/submodels/coordinates';

export class LocationHelper {

  getRadiusDistanceInKm(point1: Coordinates, point2: Coordinates) {
    return point1 && point2 ? Math.floor(this.getRadiusDistanceInMeters(point1, point2) / 1000) : 100;
  }

  getRadiusDistanceInMeters(point1: Coordinates, point2: Coordinates) {
    const toRadian = (angle: number | null) => (Math.PI / 180) * (angle || 1);
    const distance = (a: number | null, b: number | null) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return (Math.PI / 180) * (a - b);
    };
    const RADIUS_OF_EARTH_IN_KM = 6371000;

    const dLat = distance(point2.latitude, point1.latitude);
    const dLon = distance(point2.longitude, point1.longitude);

    const rLat1 = toRadian(point1.latitude);
    const rLat2 = toRadian(point2.latitude);

    // Haversine Formula
    const a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(rLat1) * Math.cos(rLat2);
    const c = 2 * Math.asin(Math.sqrt(a));

    return RADIUS_OF_EARTH_IN_KM * c;
  }
}

export default new LocationHelper();
