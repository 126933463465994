import { Dialog as HeadlessUIDialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useEffect, useState } from "react";
import store from "../state-store.tsx";
import { Capacitor } from "@capacitor/core";

export default function Dialog(props: {
  key?: string;
  show: boolean;
  children: ReactNode;
  footer?: ReactNode;
  title?: string;
  classes?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose?: Function;
}) {
  const [showDialog, setShowDialog] = useState(props.show || false);

  useEffect(() => {
    setShowDialog(props.show);
    // document.addEventListener('click', (e: MouseEvent) => {
    //   console.log('e', e);
    //   let found = false;
    //   let el = e.target as HTMLElement;
    //   while(el.parentElement) {
    //     if (el.parentElement.getAttribute('role') === 'dialog') {
    //       found = true;
    //     }
    //     el = el.parentElement;
    //   }
    //   if (found) {
    //     console.log('found true');
    //     setShowDialog(true);
    //   }
    // })
  }, [props.show]);

  return (
    <>
      {/*<span className={'absolute bottom-44 left-0 text-4xl text-white z-50'}>showDialog Dialog: {showDialog.toString()}</span>*/}
      <Transition appear show={showDialog} as={Fragment}>
        <HeadlessUIDialog
          as="div"
          className="relative z-50 overflow-y-auto"
          onClose={() => {
            if (
              !store.getState("lastDialogKey") ||
              props.key === store.getState("lastDialogKey")
            ) {
              setShowDialog(false);
              if (props.onClose) {
                props.onClose();
              }
            }
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div
            className={`overflow-y-auto fixed left-0 -top-8 z-50 w-full sm:top-0 ${Capacitor.getPlatform() === "ios" ? "mt-8" : ""}`}
          >
            <div className="flex min-h-full items-center justify-center p-4 text-center overflow-y-auto">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <HeadlessUIDialog.Panel
                  className={`overflow-y-auto pb-8 w-full md:max-w-screen-lg transform rounded-2xl p-6 text-left align-middle shadow-xl transition-all bg-gray-100 ${props.classes}`}
                >
                  <HeadlessUIDialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <span>{props.title || ""}</span>
                    <div
                      className="absolute right-4 font-extrabold cursor-pointer"
                      onClick={() => {
                        if (
                          !store.getState("lastDialogKey") ||
                          props.key === store.getState("lastDialogKey")
                        ) {
                          setShowDialog(false);
                          if (props.onClose) {
                            props.onClose();
                          }
                        }
                      }}
                    >
                      X
                    </div>
                  </HeadlessUIDialog.Title>
                  <div>{props.children}</div>
                  <div className="absolute py-2 w-full">
                    <div className="flex justify-center">{props.footer}</div>
                  </div>
                </HeadlessUIDialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </HeadlessUIDialog>
      </Transition>
    </>
  );
}
