import {Fragment, useEffect, useState} from "react";
import scuverService from "../services/scuver.service.ts";
import store from "../state-store.tsx";
import {User} from "../models/user.ts";
import {Order} from "../models/order.ts";
import {EllipsisVerticalIcon} from "@heroicons/react/24/outline";
import {Menu, Transition} from "@headlessui/react";
import Header from "../components/Header.tsx";
import {t} from "i18next";
import Image from "../components/Image.tsx";
import {useNavigate} from "react-router-dom";
import authService from "../services/auth.service.ts";

export default function OrderHistory() {
    const [orders, setOrders] = useState<Array<Order>>([]);
    const [user, setUser] = useState<User>({} as User);
    const navigate = useNavigate();
    const [isAnon, setIsAnon] = useState(false);
    store.observeState("isAnon")?.subscribe((v) => {
        console.log('isAnon', isAnon, v);
        if (!isAnon && v) {
            setIsAnon(true);
        } else if (isAnon && !v) {
            setIsAnon(false);
        }
    });

    useEffect(() => {
        store.setState("userMenuOpen", false);
        authService.observeCurrentUser().subscribe((u) => {
            if (u) {
                setUser(u);
            }
        });
    }, []);

    useEffect(() => {
        if (!isAnon && user.uid) {
            scuverService
                .getRecordsByProperty("orders", "user.uid", "==", user.uid)
                .then((l) => {
                    if (l && l.length) {
                        console.log("HISTORICO", l);
                        setOrders(l.reverse());
                    }
                });
        } else {
            const localStorageOrders = localStorage.getItem("orders");
            if (localStorageOrders) {
                setOrders(JSON.parse(localStorageOrders));
            }
        }
    }, [user]);

    function viewOrder(e, order: Order) {
        e.preventDefault();
        navigate(`/order/${order.uid}`);
    }

    function repeatOrder(e, order: Order) {
        e.preventDefault();
        console.log("Trying to repeat order", order.uid);
        alert("Not Supported Yet");
        // scuverService.getRecord('shops', order.shop.uid).then(s => {
        //   store.setState('shop', s);
        //   orderService.setCurrentOrder({...order, shop: s, uid: UIDGenerator.generate(), status: 'being-created'});
        //   navigate(`/shop`);
        //   store.setState('cartOpen', true);
        // });
    }

    return (
        <div className={"min-h-screen"}>
            <Header/>
            <main className="flex w-[99vw] px-3 md:p-4 text-gray-800 justify-center">
                <div className="bg-white">
                    <div className="py-2 sm:py-4 max-w-4xl">
                        <div className="mx-auto max-w-[99vw] sm:px-2 lg:px-8">
                            <div className="mx-auto max-w-[99vw] px-4 lg:px-0">
                                <h3 className="text-lg font-bold text-gray-700 sm:text-xl">
                                    {t("Order history")}
                                </h3>
                            </div>
                        </div>

                        <div className="mt-6 text-gray-500">
                            <div className="mx-auto sm:px-2 lg:px-8">
                                <div className="mx-auto space-y-8 sm:px-4 lg:px-0">
                                    {orders.length === 0 && "Sem encomendas ainda."}
                                    {orders.length && orders.map((order) => (
                                        <div
                                            key={order.uid}
                                            className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                                        >
                                            <h3 className="sr-only">
                                                <time dateTime={order.submittedAt}>
                                                    {order.submittedAt}
                                                </time>
                                            </h3>

                                            <div
                                                className="flex items-center border-b border-gray-200 p-4 sm:grid grid-cols-7 gap-x-6 sm:p-6">
                                                <dl className="grid flex-1 grid-cols-3 gap-x-6 text-sm col-span-5">
                                                    <div>
                                                        <dt className="font-medium text-gray-900">Ref.</dt>
                                                        <dd className="mt-1 text-gray-500 truncate whitespace-nowrap">
                                                            {order.uid}
                                                        </dd>
                                                    </div>
                                                    <div className="hidden sm:block">
                                                        <dt className="font-medium text-gray-900">Data</dt>
                                                        <dd className="mt-1 text-gray-500">
                                                            <time dateTime={order.submittedAt}>
                                                                {order.submittedAt}
                                                            </time>
                                                        </dd>
                                                    </div>
                                                    <div>
                                                        <dt className="font-medium text-gray-900">Total</dt>
                                                        <dd className="mt-1 font-medium text-gray-900">
                                                            {order.total}
                                                        </dd>
                                                    </div>
                                                </dl>

                                                <Menu
                                                    as="div"
                                                    className="relative flex justify-end lg:hidden"
                                                >
                                                    <div className="flex items-center">
                                                        <Menu.Button
                                                            className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                                                            <span className="sr-only">Menu {order.uid}</span>
                                                            <EllipsisVerticalIcon
                                                                className="h-6 w-6"
                                                                aria-hidden="true"
                                                            />
                                                        </Menu.Button>
                                                    </div>

                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                <Menu.Item>
                                                                    {({active}) => (
                                                                        <a
                                                                            href={"#"}
                                                                            onClick={(e) => viewOrder(e, order)}
                                                                            className={`${active ? "bg-gray-100 text-gray-900" : "text-gray-700"} block px-4 py-2 text-sm`}
                                                                        >
                                                                            {t("View")}
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({active}) => (
                                                                        <a
                                                                            href={"#"}
                                                                            onClick={(e) => repeatOrder(e, order)}
                                                                            className={`${active ? "bg-gray-100 text-gray-900" : "text-gray-700"} block px-4 py-2 text-sm`}
                                                                        >
                                                                            {t("Repeat")}
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>

                                                <div
                                                    className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                                                    <a
                                                        href={"#"}
                                                        onClick={(e) => viewOrder(e, order)}
                                                        className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        <span>{t("View")}</span>
                                                        <span className="sr-only">{order.uid}</span>
                                                    </a>
                                                    <a
                                                        href={"#"}
                                                        onClick={(e) => repeatOrder(e, order)}
                                                        className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    >
                                                        <span>{t("Repeat")}</span>
                                                        <span className="sr-only">{order.uid}</span>
                                                    </a>
                                                </div>
                                            </div>

                                            {/* Products */}
                                            <h4 className="sr-only">Artigos</h4>
                                            <ul role="list" className="divide-y divide-gray-200">
                                                {order.orderItems.map((product, it) => (
                                                    <li
                                                        key={`${product.uid}_${it}`}
                                                        className="p-4 sm:p-6"
                                                    >
                                                        <div className="flex items-center sm:items-start">
                                                            <div className="">
                                                                <Image
                                                                    src={product && product.photoUrl}
                                                                    classes="w-24 h-14 rounded-2xl border border-[#ccc]"
                                                                />
                                                            </div>
                                                            <div className="ml-6 flex-1 text-sm">
                                                                <div
                                                                    className="font-medium text-gray-900 sm:flex sm:justify-between">
                                                                    <h5>{product?.name}</h5>
                                                                    <p className="mt-2 sm:mt-0">
                                                                        €{product.price}
                                                                    </p>
                                                                </div>
                                                                <p className="hidden text-gray-500 sm:mt-2 sm:block">
                                                                    {product.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
