import {getApp, getApps, initializeApp} from "firebase/app";
import {browserLocalPersistence, getAuth, initializeAuth} from 'firebase/auth';
import {getMessaging, getToken, isSupported, onMessage} from 'firebase/messaging';
import {Capacitor} from '@capacitor/core';
import {FirebaseMessaging} from '@capacitor-firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  // apiKey: "AIzaSyBrseC9UM1CSwF-RKbPeRw0cXu8DO0Wm4Y",
  // authDomain: "tastic-pt.firebaseapp.com",
  // projectId: "tastic-pt",
  // storageBucket: "tastic-pt.appspot.com",
  // messagingSenderId: "932077130140",
  // appId: "1:932077130140:web:bcdeb8a33d39248158b859",
  // measurementId: "G-PHQP3GV0MV",
  // region: 'europe-west1',
  apiKey: 'AIzaSyB3McyHg_YiFoz7eU7xAkQNA9cwEkjnPvE',
  authDomain: 'scuver-data.firebaseapp.com',
  databaseURL: 'https://scuver-data-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'scuver-data',
  storageBucket: 'scuver-data.appspot.com',
  messagingSenderId: '326732084118',
  appId: '1:326732084118:web:2ad29e73e90879d830e3b7',
  measurementId: 'G-HRSGS1DXSB',
  region: 'europe-west1',
};

let app, autho;

if (!getApps().length) {
  try {
    app = initializeApp(firebaseConfig);
    autho = initializeAuth(app, {
      persistence: browserLocalPersistence,
    });
  } catch (error) {
    console.log('Error initializing app: ' + error);
  }
} else {
  app = getApp();
  autho = getAuth(app);
}

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = autho;
// await auth.setPersistence('local' as any);
export let messaging = null;
isSupported().then(v => messaging = v ? getMessaging(app) : null)
export const onMessageListener = () =>
  new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        console.log('Received message payload', payload);
        resolve(payload);
      });
    }
  });
export default app;

export const requestPermission = () => {

  isSupported().then(supported => {
    if (supported) {
      const hasToken = localStorage.getItem('firebase_token');
      if (!hasToken && Capacitor.getPlatform() !== 'ios') {
        // if (!hasToken) {
        console.log("Requesting User Permission......");
        try {
          Notification.requestPermission().then((permission) => {

            if (permission === "granted") {

              console.log("Notification User Permission Granted.");
              // @ts-ignore
              return getToken(messaging, {vapidKey: `BGzMGYXRCTS22ZhSfVpPwgZHuuqfqC3d9AqAL_WutFz7IDyGEbnt4ZKUEoeRZfFQsQbu5ac7nYHtZOR_d9dZ1Hg`})
                .then((currentToken) => {

                  if (currentToken) {

                    console.log('FCM Token: ', currentToken);
                    localStorage.setItem('firebase_token', currentToken)
                  } else {

                    console.log('Failed to generate the app registration token.');
                  }
                })
                .catch((err) => {

                  console.log('An error occurred when requesting to receive the token.', err);
                });
            } else {

              console.log("User Permission Denied.");
            }
          });
        } catch (e) {
          console.warn(e);
        }
      } else {
        console.log('FCM Token', hasToken);
      }

      if (Capacitor.getPlatform() === 'ios') {
        try {

          console.log("Requesting User Permission on iOS......");

          FirebaseMessaging.requestPermissions().then((permission) => {

            if (permission.receive === "granted") {

              console.log("Notification User Permission Granted.");
              // @ts-ignore
              return FirebaseMessaging.getToken({vapidKey: `BGzMGYXRCTS22ZhSfVpPwgZHuuqfqC3d9AqAL_WutFz7IDyGEbnt4ZKUEoeRZfFQsQbu5ac7nYHtZOR_d9dZ1Hg`})
                .then((currentToken) => {

                  console.log('FCM getToken result: ', currentToken);

                  if (currentToken.token) {

                    console.log('FCM Token: ', currentToken.token);
                    localStorage.setItem('firebase_token', currentToken.token)
                  } else {

                    console.log('Failed to generate the app registration token.');
                  }
                })
                .catch((err) => {

                  console.log('An error occurred when requesting to receive the token.', err);
                });
            } else {

              console.log("User Permission Denied.");
            }
          });
        } catch (e) {
          console.warn(e);
        }
      } else {
        console.log('FCM Token', hasToken);
      }
    }
  })
}
