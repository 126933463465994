import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {Order} from "../models/order";
import authService from "./auth.service.ts";
import store from "../state-store.tsx";
import orderHelper from "../utils/order-helper.ts";
import scuverService from "./scuver.service.ts";
import addressService from "./address.service.ts";

class OrderService {
  private currentOrder$ = new BehaviorSubject<Order>(null);
  private currentOrderSub = new Subscription();

  constructor() {
    this.trackCurrentOrder();
    this.trackAddressChange();
  }

  // --------------------------------------------------------------------------------------------------------
  // Current Order Functions
  // --------------------------------------------------------------------------------------------------------

  private trackCurrentOrder() {
    authService.observeCurrentUser().subscribe((user) => {
      console.log(
        "Order Service trackCurrentOrder user shop",
        user,
        store.getState("shop"),
      );

      this.currentOrderSub.unsubscribe();

      if (!user || !user.uid) return this.currentOrder$.next(null);
      this.currentOrderSub = this.observeUserOngoingOrder(
        user.uid,
        store.getState("shop")?.uid,
      ).subscribe((order) => {
        console.log('trackCurrentOrder observeUserOngoingOrder order changed', order);
        if (order) {
          order.shop = store.getState("shop");
          order.user = order.user && order.user.uid ? order.user : user;
        }
        this.currentOrder$.next(order);
      });
    });
  }

  private trackAddressChange() {
    addressService.observeCurrentAddress().subscribe((a) => {
      const order = this.currentOrder$.getValue();
      if (order && a?.addressLine1) {
        order.address = a;
        this.updateOrder(order);
      }
    });
  }

  async setCurrentOrder(order: Order) {
    console.log("setCurrentOrder", order);
    if (order && order.uid && order.status === "being-created") {
      order.subTotal = orderHelper.calculateSubTotal(order);
      order.total = orderHelper.calculateOrderTotal(order);
      console.log("setCurrentOrder updating order", order);
      this.currentOrder$.next(order);
      await this.removeOrdersBeingCreatedByUserExcept(order);
      await this.updateOrder(order);
    }
  }

  observeCurrentOrder(): Observable<Order> {
    return this.currentOrder$.asObservable();
  }

  // --------------------------------------------------------------------------------------------------------
  // Generic Firebase Functions
  // --------------------------------------------------------------------------------------------------------

  getOrder(uid: string): Promise<Order> {
    return scuverService.getRecord("orders", uid);
  }

  observeOrdersByUser(userId: string): Observable<Order[]> {
    return scuverService.observeRecordsByProperty(
      "orders",
      "user.uid",
      "==",
      userId,
    );
  }

  observeOrdersByShop(shopId: string): Observable<Order[]> {
    return scuverService.observeRecordsByProperty(
      "orders",
      "shop.uid",
      "==",
      shopId,
    );
  }

  getOrdersByUserAndShop(userId: string, shopId: string): Promise<Order[]> {
    return scuverService.getRecordsByProperties(
      "orders",
      ["user.uid", "shop.uid"],
      "==",
      [userId, shopId],
    );
  }

  getOrdersByUserAndShopAndTable(
    userId: string,
    shopId: string,
    table: number,
  ): Promise<Order[]> {
    return scuverService.getRecordsByProperties(
      "orders",
      ["user.uid", "shop.uid", "table"],
      "==",
      [userId, shopId, table],
    );
  }

  getOrdersByShopAndTable(shopId: string, table: number): Promise<Order[]> {
    return scuverService.getRecordsByProperties(
      "orders",
      ["shop.uid", "table"],
      "==",
      [shopId, table],
    );
  }

  async getOrderForThisUserBeingCreated(userId: string): Promise<Order> {
    const results = await scuverService.getRecordsByProperties(
      "orders",
      ["user.uid", "status"],
      "==",
      [userId, "being-created"],
    );
    return results.length ? results[results.length - 1] : null;
  }

  // observeUserOrderBeingCreated(userId: string, shopId: string): Observable<Order> {
  //   return scuverService.observeRecordByProperties('orders', ['user.uid', 'shop.uid', 'status'], '==', [userId, shopId, 'being-created'])
  //       .pipe(map((order: Order) => {
  //         console.log('observeOrderBeingCreated ', order);
  //         return order ? merge(new Order(), order) : null;
  //       }));
  // }

  observeUserOngoingOrder(userId: string, shopId: string): Observable<Order> {
    const props = ["user.uid", "status", "status"];
    const filterOps = ["==", "!=", "!="];
    const values = [userId, "completed", "cancelled"];
    if (shopId) {
      props.push("shop.uid");
      filterOps.push("==");
      values.push(shopId);
    }
    return scuverService
      .observeRecordByProperties("orders", props, filterOps, values)
      .pipe(
        map((order: Order) => {
          console.log("observeUserOngoingOrder ", order);
          return order ? (order as Order) : null;
        }),
      );
  }

  addOrder(order: Order): Promise<Order> {
    return scuverService.addOrUpdateRecord(
      "orders",
      JSON.parse(JSON.stringify(order)),
    ) as Promise<Order>;
  }

  updateOrder(order: Order): Promise<Order> {
    return scuverService.addOrUpdateRecord(
      "orders",
      JSON.parse(JSON.stringify(order)),
    ) as Promise<Order>;
  }

  removeOrder(uid: string) {
    return new Promise((resolve) => {
      console.log("Removing order ", uid);
      scuverService.removeOrder(uid).then((r) => {
        console.log("Removed order ", uid);
        this.currentOrder$.next(null);
        resolve(r);
      });
    });
  }

  observeOrder(uid: string): Observable<Order> {
    return scuverService
      .observeRecord("orders", uid)
      .pipe(
        map((order: Order) => (order && order.uid ? (order as Order) : null)),
      );
  }

  private removeOrdersBeingCreatedByUserExcept(order: Order) {
    // if (this.user && this.user.role === 'employee') {
    //   return scuverService.getRecordsByProperties(
    //       'orders',
    //       ['uid'    , 'user.uid'    , 'status' , 'table'      ],
    //       ['!='     , '=='          , '=='           , '=='],
    //       [order.uid, order.user.uid, 'being-created', order.table]
    //   ).then(records => {
    //     records.forEach(record => this.removeOrder(record.uid));
    //   });
    // } else
    if (order.user && order.user.uid) {
      return scuverService
        .getRecordsByProperties(
          "orders",
          ["uid", "user.uid", "status"],
          ["!=", "==", "=="],
          [order.uid, order.user.uid, "being-created"],
        )
        .then((records) => {
          records.forEach((record) => this.removeOrder(record.uid));
        });
    }
  }

  saveAnonymousOrderBeforeLogin() {
    localStorage.setItem(
      "anonymousOrderBeforeLogin",
      this.currentOrder$?.getValue()?.uid,
    );
  }

  getAnonymousOrderBeforeLogin() {
    return localStorage.getItem("anonymousOrderBeforeLogin");
  }

  deleteAnonymousOrderBeforeLogin() {
    localStorage.removeItem("anonymousOrderBeforeLogin");
  }
}

export default new OrderService();
