import Dialog from './Dialog.tsx';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import logo from '../assets/images/logo.svg';
import Button from './Button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faApple, faGoogle} from '@fortawesome/free-brands-svg-icons';
import authService from '../services/auth.service.ts';
import {getAllCountries} from '../models/country.ts';
import {Listbox, Transition} from '@headlessui/react';
import {ChevronUpDownIcon} from '@heroicons/react/20/solid';
import {CheckIcon} from '@heroicons/react/24/solid';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

export default function SignIn(props: {
  show: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose?: Function
}) {

  const countries = getAllCountries();
  const [showDialog, setShowDialog] = useState(props.show);
  const [country, setCountry] = useState(countries.find(c => c?.name === 'Portugal'))
  const [phone, setPhone] = useState('936256982')
  const [codeSent, setCodeSent] = useState(false)
  const [code, setCode] = useState('')
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowDialog(props.show);
  }, [props.show]);

  function googleLogin() {
    console.log('googleLogin');
    authService.signInWithGoogle().then(response => {
      console.log(response)
      setShowDialog(false);
      navigate('/home')
    });
  }

  function appleLogin() {
    authService.signInWithApple().then(response => {
      console.log(response)
      setShowDialog(false);
    });
  }

  function loginWithCode() {
    authService.insertPhoneCode(code)?.then(response => {
      console.log(response)
      setShowDialog(false);
    });
  }

  function loginWithPhone() {
    if (phone && country && phone.length > 5 && phone.length < 15) {
      authService.signInWithPhone(`+${country.dialCode}${phone}`).then(() => {
        setCodeSent(true);
        toast.success(t('Code sent by SMS'));
      }).catch(err => {
        console.error('loginWithPhone ERROR');
        console.error(err);
        toast.error(err.message);
      })
    } else {
      setCodeSent(false);
      toast.error(t('Invalid Phone Number'));
    }
  }

  return (
    <Dialog show={showDialog} onClose={() => {
      setShowDialog(false);
      setCodeSent(false);
      if (props.onClose) {
        props.onClose();
      }
    }}>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-8 lg:px-8">
        <div className="md:mx-auto md:w-full md:max-w-sm">
          <img
            className="mx-auto h-16 w-auto"
            src={logo}
            alt="Your Company"
          />
        </div>
        <div className="mt-12 md:mx-auto md:w-full md:max-w-sm">
          <div className="space-y-6">
            {codeSent &&
              <div className="space-y-6">
                <div>
                  <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('Code Received')}
                  </label>
                  <div className="mt-2">
                    <input
                      id="code"
                      name="code"
                      type="text"
                      autoComplete="code"
                      required
                      autoFocus={true}
                      onChange={e => setCode(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 md:text-sm md:leading-6"
                    />
                  </div>
                </div>
                <Button onClick={loginWithCode} type={'square'} text={t('Submit')}/>
              </div>}
            {!codeSent &&
              <>
                <div className={'text-gray-800'}>
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('Country')}
                  </label>
                  <div className="mt-3 z-50">
                    <Listbox value={country} onChange={setCountry}>
                      <div className="relative mt-1">
                        <Listbox.Button
                          className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 md:text-sm">
                          <span className="block truncate">{country?.name} (+{country?.dialCode})</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:text-sm">
                            {countries.map((country, idx) => (
                              <Listbox.Option
                                key={idx}
                                className={({active}) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                  }`
                                }
                                value={country}
                              >
                                {({selected}) => (
                                  <>
                                  <span
                                    className={`block truncate ${
                                      selected ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {country.name} (+{country.dialCode})
                                  </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                    </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  <div id={'recaptcha'} className={'recaptcha'}></div>
                </div>
                <div>
                  <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('Phone Number')}
                  </label>
                  <div className="mt-2">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      autoComplete="phone"
                      required
                      autoFocus={true}
                      onChange={e => setPhone(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 md:text-sm md:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <Button onClick={loginWithPhone} type={'square'} text={t('Login / SignUp')}/>
                </div>
              </>
            }
            <div className="mt-10">
              <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200"/>
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-gray-100 px-6 text-gray-900">{t('Or continue with')}</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-4">
                <a
                  onClick={googleLogin}
                  href="#"
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-[#de5246] px-3 py-1.5 text-white hover:text-white hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                >
                  <FontAwesomeIcon icon={faGoogle}/>
                  <span className="text-sm font-semibold leading-6">Google</span>
                </a>

                <a
                  onClick={appleLogin}
                  href="#"
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-black px-3 py-1.5 text-white hover:text-white hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray"
                >
                  <FontAwesomeIcon icon={faApple}/>
                  <span className="text-sm font-semibold leading-6">Apple</span>
                </a>
              </div>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            {/*{t('Not a member')}?{' '}*/}
            {/*<a href="#" className="font-semibold leading-6 text-primary-600 hover:text-primary-500">*/}
            {/*  {t('Sign up now')}*/}
            {/*</a>*/}
            {t('By Signing Up or Signing In you are accepting our')}{' '}
            <a href="#" className="font-semibold leading-6 text-primary-600 hover:text-primary-500">
              {t('terms and conditions')}.
            </a>
            {/*{' '}{t('to access promotions')}{', '}{t('saved addresses')}{', '}{t('payments')}{', '}{t('order history')}{' '}{t('and more')}.*/}
          </p>
        </div>
      </div>
    </Dialog>
  );
}
