import {Shop} from '../models/shop';
import {DaySchedule} from '../models/submodels/timetable';
import {MyDate, MyMoment, MyTime, TimeHelper} from './time-helper';

class ShopHelper {

  getArrivalTimesOn(shop: Shop, day: string, at: MyTime, kms?: number) {
    const preparationTime = MyTime.parse(shop.preparationTime);
    const timeStepInMin   = 15;
    const minimumTime     = MyTime.minutesToTime(at.toMinutes() + preparationTime.toMinutes() + (kms ? (10 + (kms * 2)) : 15));
    const daySchedule     = shop.timetable[day] as DaySchedule;

    if (daySchedule.isClosed) return [];

    const times: MyTime[] = [];
    const time = kms ? minimumTime : MyTime.minutesToTime(Math.round(minimumTime.toMinutes() / timeStepInMin) * timeStepInMin);

    daySchedule.workingPeriods.forEach((workingPeriod) => {
      if (workingPeriod.startTime && workingPeriod.endTime) {
        const startTime = MyTime.parse(workingPeriod.startTime);
        const endTime   = MyTime.parse(workingPeriod.endTime);

        const from = MyTime.minutesToTime( startTime.toMinutes() + preparationTime.toMinutes() + 5);
        const to   = MyTime.minutesToTime(   endTime.toMinutes() + preparationTime.toMinutes());

        while (time.toMinutes() < to.toMinutes()) {
          if (time.isWithinTimeSpan(from, to)) times.push(time.copy());
          time.addMinutes(timeStepInMin);
        }
      }
    });

    return times;
  }

  getArrivalTimesOfEarliestDay(shop: Shop) {
    const days  = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const today = MyDate.getCurrentDay();
    const todayIndex = days.findIndex((day) => day === today);
    const daysSofted = days.slice(todayIndex, days.length).concat(days.slice(0, todayIndex));

    for (const day of daysSofted) {
      const at = day === MyDate.getCurrentDay() ? MyTime.getCurrentTime() : MyTime.parse('00:00');
      const arrivalTimes = this.getArrivalTimesOn(shop, day, at);
      if (arrivalTimes.length) return arrivalTimes;
    }

    return [];
  }

  getArrivalTimesOfToday(shop: Shop, kms?: number) {
    return this.getArrivalTimesOn(shop, MyDate.getCurrentDay(), MyTime.getCurrentTime(), kms);
  }

  getArrivalTimesOfTomorrow(shop: Shop) {
    return this.getArrivalTimesOn(shop, MyDate.getNextDay(), MyTime.parse('00:00'));
  }

  earliestArrivalTime(shop: Shop) {
    const arrivalTimes = this.getArrivalTimesOfEarliestDay(shop);
    return arrivalTimes[0];
  }

  latestArrivalTime(shop: Shop) {
    const arrivalTimes = this.getArrivalTimesOfToday(shop);
    return arrivalTimes[arrivalTimes.length];
  }

  getTodaySchedule(shop: Shop) {
    const today = MyDate.getCurrentDay();
    return shop.timetable[today] as DaySchedule;
  }

  getTomorrowSchedule(shop: Shop) {
    const tomorrow = MyDate.getNextDay();
    return shop.timetable[tomorrow] as DaySchedule;
  }

  getScheduleAsArray(shop: Shop) {
    const shopSchedule: {day: string, schedule: DaySchedule}[] = [];

    shopSchedule.push({day: 'monday'   , schedule: shop.timetable.monday   });
    shopSchedule.push({day: 'tuesday'  , schedule: shop.timetable.tuesday  });
    shopSchedule.push({day: 'wednesday', schedule: shop.timetable.wednesday});
    shopSchedule.push({day: 'thursday' , schedule: shop.timetable.thursday });
    shopSchedule.push({day: 'friday'   , schedule: shop.timetable.friday   });
    shopSchedule.push({day: 'saturday' , schedule: shop.timetable.saturday });
    shopSchedule.push({day: 'sunday'   , schedule: shop.timetable.sunday   });

    return shopSchedule;
  }

  isOpenOn(shop: Shop, day: string, time: MyTime) {
    const daySchedule = shop.timetable[day] as DaySchedule;

    if (daySchedule.isClosed || !daySchedule.workingPeriods || !daySchedule.workingPeriods.length || !daySchedule.workingPeriods.map) return false;

    for (const workingPeriod of daySchedule.workingPeriods) {
      const startTime = TimeHelper.parse(workingPeriod.startTime);
      const endTime = TimeHelper.parse(workingPeriod.endTime);
      if (TimeHelper.isWithinTimeSpan(time, startTime, endTime)) return true;
    }
    return false;
  }

  isOpenForMeal(shop: Shop) {
    const today = TimeHelper.getCurrentDay();
    const now = MyTime.getCurrentTime();
    const shopTime = MyTime.parse(shop.preparationTime);
    now.addMinutes(shopTime.minute);
    return this.isOpenOn(shop, today, now);
  }

  closestOrderingTime(shop) {
    const today = TimeHelper.getCurrentDay();
    if (shop.suspendOrders) {
      return 'suspended'
    }
    const curTime = MyTime.getCurrentTime();
    curTime.addMinutes(MyTime.parse(shop.preparationTime).toMinutes());
    if (this.isOpenOn(shop, today, curTime)) {
      return MyTime.getCurrentTime().toString();
    } else {
      const daySchedule = shop.timetable[today];
      if (daySchedule.isClosed || !daySchedule.workingPeriods || !daySchedule.workingPeriods.length || !Array.isArray(daySchedule.workingPeriods)) return 'closed';

      for (const workingPeriod of daySchedule.workingPeriods) {
        if (workingPeriod.startTime) {
          const startTime = TimeHelper.parse(workingPeriod.startTime);
          const myMoment = MyMoment.todayAt(startTime);
          if (myMoment.toTodayDateWithTime() > new Date()) {
            // myMoment.time.subtractMinutes(MyTime.parse(shop.preparationTime).toMinutes())
            return myMoment.time.toString();
          }
        }
      }
      return 'closed'
    }
  }

  isOpenTodayAt(shop: Shop, time: MyTime) {
    const today = TimeHelper.getCurrentDay();
    return this.isOpenOn(shop, today, time);
  }

  isOpenToday(shop: Shop) {
    const today = TimeHelper.getCurrentDay();
    return !shop.timetable[today].isClosed;
  }

  isClosedToday(shop: Shop) {
    const today = TimeHelper.getCurrentDay();
    return shop.timetable[today].isClosed;
  }

  // isLocationWithinBounds(shop: Shop, point: Coordinates) {
  //   const distanceInKm = this.locationHelper.getRadiusDistanceInKm(shop.address.coordinates, point);
  //   return (distanceInKm <= (shop.deliveryCoverage));
  // }
  //
  // isLocationWithinBoundsForInShop(shop: Shop, point: Coordinates) {
  //   const distanceInMeters = this.locationHelper.getRadiusDistanceInMeters(shop.address.coordinates, point);
  //   return (distanceInMeters  <= 100);
  // }

  getDeliveryFee(shop: Shop, distanceInKm: number) {
    let deliveryFee = shop.deliveryFeeMin;
    if (distanceInKm > 1) {
      deliveryFee = shop.deliveryFeeMin + (shop.deliveryFeePerKm * (distanceInKm - 1));
    }
    deliveryFee = Math.min(deliveryFee, shop.deliveryFeeMax);
    deliveryFee = Math.round((deliveryFee + Number.EPSILON) * 100) / 100;
    return deliveryFee;
  }

  getNextOrderTime(shop: Shop, distance): MyMoment {
    console.log('distance', distance);
    if (distance < 1) {
      distance = 1;
    }
    if (distance > 30) {
      distance = 30
    }
    const shopTime = MyTime.parse(shop.preparationTime);
    const openAt = this.closestOrderingTime(shop);
    if (openAt === 'closed') {
      alert('Shop is closed today');
      throw 'Shop is closed today';
    }
    const arrivalTime = MyMoment.todayAt(MyTime.parse(openAt));
    arrivalTime.time.addMinutes(shopTime.minute + (distance * 2));
    return arrivalTime;
  }
}

export default new ShopHelper();
