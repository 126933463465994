import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { ItemPage } from "./pages/Item.tsx";
import { Loading } from "./pages/Loading.tsx";
import { ShopPage } from "./pages/Shop.tsx";
import { Welcome } from "./pages/Welcome.tsx";
import Privacy from "./components/Privacy.tsx";
import placeholder from "./assets/images/placeholder.jpg";
import { OrderStatusPage } from "./pages/OrderStatus.tsx";
import Notification from "./components/Notification.tsx";
import Contacts from "./components/Contacts.tsx";
import OrderHistory from "./pages/OrderHistory.tsx";
import "./App.css";
import { Rewards } from "./pages/Rewards.tsx";
import RewardsResults from "./pages/RewardsResults.tsx";
import { User } from "./models/user.ts";
import authService from "./services/auth.service.ts";
import OrderPage from "./pages/Order.tsx";

const App = () => {
  const [user, setUser] = useState<User>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("user", user);
    setLoading(false);
    setIsLoggedIn(!!user);
    authService.observeCurrentUser().subscribe((u) => {
      setUser(u);
    });
  }, [user]);

  return (
    <>
      <BrowserRouter>
        <Loading show={loading} />
        <Routes>
          <Route
            path="/"
            element={
              loading ? (
                <div className="bg-secondary-100 flex justify-center items-center w-screen h-screen absolute top-0 left-0">
                  <img
                    className="h-[60vh] w-6/12 shadow-xl rounded-lg"
                    src={placeholder}
                  />
                </div>
              ) : isLoggedIn ? (
                <Home />
              ) : (
                <Welcome />
              )
            }
          />
          <Route path="/home" element={<Home />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/contacts" element={<Welcome />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/rewards/*" element={<Rewards />} />
          <Route path="/results/:objectId" element={<RewardsResults />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/item/:uid" element={<ItemPage />} />
          <Route path="/order/:uid" element={<OrderPage />} />
          <Route path="/order-status" element={<OrderStatusPage />} />
          <Route path="/order-status/:uid" element={<OrderStatusPage />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/*" element={<ShopPage />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
      <Privacy />
      <Contacts />
      <Notification />
    </>
  );
};
export default App;
