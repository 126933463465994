import {useEffect, useRef} from 'react';

export default function Selectable(props: {
  type?: string,
  onSelected?: any,
  checked?: boolean,
  color?: string,
  label?: string,
  classes?: string,
  group?: string
}) {

  const thisRef = useRef(null);
  useEffect(() => {
    if (props.checked) {
      thisRef.current?.setAttribute('checked', true);
    }
  }, [props.checked]);

  return (
    <div className={`flex flex-row items-center ${props.classes ? (' ' + props.classes) : ''}`}>
      <input
        ref={thisRef}
        name={`${props.group}`}
        type={props.type && props.type === 'checkbox' ? 'checkbox' : 'radio'}
        onClick={props.onSelected}
        className={`${thisRef.current?.checked ? (props.color ? 'bg-'+props.color : 'bg-primary-600 ring-1 ring-gray-400') : 'bg-gray-300'} ${props.type === 'checkbox' ? '' : 'rounded-full'} text-primary-600 border-transparent ring-0 focus:ring-0 focus:border-transparent focus:border-0 focus:outline-0 cursor-pointer block border-none border-0`}
        style={{boxShadow: 'none'}}
      />
      <span onClick={props.onSelected} className={'ml-2 text-sm'}>{props.label}</span>
    </div>
  );
}
