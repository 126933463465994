import ReactDOM from "react-dom/client";
import App from "./App";
import './utils/logger';
import * as Sentry from "@sentry/react";
import "./i18n";
import "./index.css";

Sentry.init({
  dsn: "https://97a4e8fc4a1fe6bfeee5fde382291558@o4505843583090688.ingest.sentry.io/4505843585318912",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/scuver\.services/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <App />
);
