import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import scuverService from '../services/scuver.service.ts';

const RewardsResultsPage = () => {
  const { objectId } = useParams();

  const [host, setHost] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [rewardsOne, setRewardsOne] = useState(null);
  const [rewardsTwo, setRewardsTwo] = useState(null);
  const [rewardsThree, setRewardsThree] = useState(null);
  const [ordersOne, setOrdersOne] = useState(null);
  const [ordersTwo, setOrdersTwo] = useState(null);
  const [ordersThree, setOrdersThree] = useState(null);
  const [orders, setOrders] = useState(null);
  const [earnings, setEarnings] = useState(0);

  useEffect(() => {
    scuverService.getCollection('rewards').then((response) => {
      setRewards(response);
      setHost(response.find((reward) => reward._id === objectId));
    });
    scuverService.getCollection('orders').then((response) => {
      setOrders(response);
    });
  }, []);

  useEffect(() => {
    if (rewards && host && orders) {
      const rewardsOneList = rewards.filter((reward) => reward.hostNumber === host.uid || reward.hostCode === host.code);
      const rewardsTwoList = rewards.filter((reward) => rewardsOneList.find(hostOne => reward.hostNumber === hostOne.uid || reward.hostCode === hostOne.code));
      const rewardsThreeList = rewards.filter((reward) => rewardsTwoList.find(hostTwo => reward.hostNumber === hostTwo.uid || reward.hostCode === hostTwo.code));

      const ordersOneList = orders.filter((order) => rewardsOneList.find(reward => order.user.phoneNumber === reward.uid));
      const earningsOneValue = ordersOneList.reduce((total, encomenda) => {
        return total + encomenda.subTotal;
      }, 0);
      const ordersTwoList = orders.filter((order) => rewardsTwoList.find(reward => order.user.phoneNumber === reward.uid));;
      const earningsTwoValue = ordersTwoList.reduce((total, encomenda) => {
        return total + encomenda.subTotal;
      }, 0);
      const ordersThreeList = orders.filter((order) => rewardsThreeList.find(reward => order.user.phoneNumber === reward.uid));;
      const earningsThreeValue = ordersThreeList.reduce((total, encomenda) => {
        return total + encomenda.subTotal;
      }, 0);

      const rewardsOneValue = 0.025 * earningsOneValue;
      const rewardsTwoValue = 0.015 * earningsTwoValue;
      const rewardsThreeValue = 0.01 * earningsThreeValue;

      setOrdersOne(ordersOneList);
      setOrdersTwo(ordersTwoList);
      setOrdersThree(ordersThreeList);
      setRewardsOne(rewardsOneList);
      setRewardsTwo(rewardsTwoList);
      setRewardsThree(rewardsThreeList);
      setEarnings(rewardsOneValue + rewardsTwoValue + rewardsThreeValue);
    }
  }, [rewards, host, orders]);

  return (
    <div className="bg-gradient-to-r from-primary-500 to-primary-700 min-h-screen text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-4xl font-bold mb-4">Resultados nos últimos 7 dias</h2>
        <div className="bg-white bg-opacity-30 p-6 rounded-md">
          <p className="text-2xl">Convidados Diretos: {rewardsOne ? rewardsOne.length : 0} Encomendas: {ordersOne ? ordersOne.length : 0}</p>
          <p className="text-2xl">Convidados Secundários: {rewardsTwo ? rewardsTwo.length : 0} Encomendas: {ordersTwo ? ordersTwo.length : 0}</p>
          <p className="text-2xl">Convidados Terciários: {rewardsThree ? rewardsThree.length : 0} Encomendas: {ordersThree ? ordersThree.length : 0}</p>
          <p className="text-2xl mt-4">Ganhos: {earnings.toFixed(2)}€</p>
        </div>
      </div>
    </div>
  );
};

export default RewardsResultsPage;
