import axios, {AxiosResponse} from 'axios';
import {API_URL} from '../constants';

const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 30000;

class HttpHelper {

  headers: any = {};

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': 'Authorization, Lang'
    };
  }

  get(url: string) {
    return axios.get(url).then((response: AxiosResponse) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw response;
      }
    });
  }

  post(url: string, data: any) {
    return axios.post(url, data).then((response: AxiosResponse) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw response;
      }
    });
  }

  delete(url: string) {
    return axios.delete(url).then((response: AxiosResponse) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw response;
      }
    });
  }

  bypassCors(method: string, url: string, body?: any, headers?: any, isJSON = true): Promise<any> {

    if (headers) {
      Object.keys(headers).forEach(k => {
        this.headers[k] = headers[k];
      });
    }

    return axiosInstance.post(`${API_URL}/corsCall`, {
      method,
      url,
      body,
      headers,
      isJSON
    }, {headers: this.headers}).then((response: AxiosResponse) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw response;
      }
    });
  }
}

export default new HttpHelper();
