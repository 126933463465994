import {useTranslation} from "react-i18next";
import logoAndText from '../assets/images/logo_and_text_white.svg';
import logo from '../assets/images/logo_white.svg';
import {useEffect, useState} from 'react';
import scuverService from '../services/scuver.service.ts';
import {toast} from 'react-hot-toast';
import {UIDGenerator} from '../utils/uid-generator.ts';
import {useNavigate} from 'react-router-dom';

export const Rewards = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [payBy, setPayBy] = useState('mbway')
  const [code, setCode] = useState('')
  const [hostNumber, setHostNumber] = useState('')
  const [hostCode, setHostCode] = useState('')
  const [hostId, setHostId] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const pathname = location.pathname?.substring(location.pathname.lastIndexOf('/')+1);
    if (pathname) {
      if (pathname.substring(0, 1) === '9') {
        setHostNumber(pathname)
      } else {
        setHostCode(pathname)
      }
    } else {
      setHostNumber('911871812')
    }
  }, []);

  async function save() {
    const exist = await scuverService.getRecord('rewards', phoneNumber);
    if (!exist) {
      const newCode = UIDGenerator.generate();
      scuverService.addOrUpdateRecord('rewards', {
        uid: phoneNumber,
        hostNumber,
        hostCode,
        code: newCode,
        email,
        payBy
      }).then(r => {
        toast.success('Inscrição enviada com sucesso.')
        setCode(newCode)
        setHostId(r._id)
        scuverService.sendEmail(email, 'Bem-vind@ á comunidade Tastic!',`<div style="font-size: 2em; font-family: Roboto; padding: 2em;"><p>Obrigado por se registar na comunidade Tastic! Os seus links para partilhar são: <a href="http://tastic.pt/rewards/${newCode}" target="_blank">http://tastic.pt/rewards/${newCode}</a> e <a href="http://tastic.pt/${phoneNumber}" target="_blank">http://tastic.pt/${phoneNumber}</a></p><div className={'mt-4'}>
                    Utilize este link para acompanhar os seus resultados: <a href={'http://tastic.pt/results/' + hostId} target={'_blank'}>http://tastic.pt/results/{hostId}</a>
                  </div></div>`, true)
        // scuverService.sendSMS(phoneNumber, `Obrigado por se registar na comunidade Tastic! Os seus links para partilhar sao: http://tastic.pt/rewards/${newCode} e http://tastic.pt/${phoneNumber}`)
      })
    } else {
      toast.error('Este telefone já está registado.')
    }
  }

  return (
    <>
      <div className={`bg-repeat bg-contain md:bg-cover bg-top w-[99vw] z-0 pt-2 md:px-8 md:p-4 bg-[url('/src/assets/images/rewards_mobile.jpg')] md:bg-[url('/src/assets/images/rewards.jpg')]`}>
        <div className="absolute w-full h-full bg-black opacity-10 top-0 left-0 z-0"> </div>
        <main className="flex z-20 w-full">
          <div className="flex flex-col z-20 w-full pb-20 md:pb-0">
            <div className="flex justify-center md:justify-between z-20 items-center mx-auto mt-4">
              <div className="z-20 hidden md:block">
                <img src={logoAndText} className="w-42 h-14"/>
              </div>
              <div className="z-20 md:hidden flex flex-col items-center mt-1">
                <img src={logo} className="w-8/12 logo"/>
              </div>
            </div>
            <div className="pb-8 w-full md:max-w-screen-lg md:rounded-3xl transform p-6 text-left align-middle shadow-xl transition-all bg-white md:bg-gray-100 flex flex-col z-20 space-y-8 mt-8 items-center md:items-start font-signika max-w-xl mx-auto text-lg text-gray-800">
              <header className="text-center py-2 text-gray-700 text-3xl w-full">
                <h1 className="text-4xl font-bold">Bem-vindo à Comunidade Tastic!</h1>
              </header>
              {code ? <div className={'h-[62vh] flex flex-col space-y-4'}>
                  <p>Parabéns! Já está inscrito.</p>
                  <p>Agora pode começar a partilhar o seu link e ganhar recompensas:</p>
                  <ul>
                    <li><a href={'http://tastic.pt/' + phoneNumber} target={'_blank'}>http://tastic.pt/{phoneNumber}</a></li>
                    <li><a href={'http://tastic.pt/rewards/' + code} target={'_blank'}>http://tastic.pt/rewards/{code}</a></li>
                  </ul>
                  <span className={'text-sm'}>Ambos os links servem o mesmo propósito. O segundo serve o propósito de poder partilhar nas redes sociais e o primeiro é mais simples para memorizar.</span>
                  <div className={'mt-4'}>
                    Utilize este link para acompanhar os seus resultados: <a href={'http://tastic.pt/results/' + hostId} target={'_blank'}>http://tastic.pt/results/{hostId}</a>
                  </div>
                </div> :
              <div>
                <section className="container mx-auto mt-0 mb-8 md:p-4 bg-white md:shadow-lg rounded-md">
                  <p className="text-lg">Na Comunidade Tastic, estamos a criar algo verdadeiramente especial. Somos uma comunidade unida,
                    conectada pela paixão por refeições deliciosas e preços acessíveis. Acreditamos que todos merecem desfrutar
                    de uma boa refeição sem comprometer o orçamento. É por isso que criámos a plataforma de entrega de
                    refeições ao domicílio Tastic, que não só oferece pratos deliciosos, mas também preços que desafiam
                    as grandes multinacionais.</p>

                  <p className="text-lg">Ao fazer parte da Comunidade Tastic, não fará apenas parte de uma revolução gastronómica, mas
                    também tem a oportunidade de ganhar dinheiro extra enquanto desfruta de refeições incríveis. Queremos que
                    todos beneficiem das vantagens que a Tastic proporciona, e é por isso que criámos um programa de recompensas
                    empolgante.</p>
                </section>

                <section className="container mx-auto my-8 md:p-4 bg-white md:shadow-lg rounded-md">
                  <h2 className="text-xl font-bold mb-4">Como Funciona:</h2>

                  <ol className="list-decimal ml-6">
                    <li className="text-lg mb-2"><strong>Espalhe a Palavra e Poupe:</strong> Ao convidar amigos e familiares para
                      aderirem à Tastic, não só os ajuda a poupar, mas também ganha 2.5% do valor de cada pedido
                      feito pelos seus convidados (excluindo taxa de entrega).
                      Uma forma simples de partilhar o amor pela Tastic e ser recompensado por isso.</li>

                    <li className="text-lg mb-2"><strong>Cadeia de Recompensas:</strong> Não termina aí! Receba 1.5% do valor de
                      cada pedido feito pelos convidados dos seus convidados e 1% do valor de cada pedido feito pelos
                      convidados dos convidados dos seus convidados. Imagine o potencial de ganhos ao construir uma comunidade
                      próspera!</li>

                    <li className="text-lg mb-2"><strong>Rendimento Mensal Atrativo:</strong> Se convidar pessoas suficientes e todos cumprirem os
                      requisitos mínimos, pode ganhar um ótimo salário passivamente.
                      Convide bastantes pessoas, e se essas pessoas também convidarem outras, e assim sucessivamente, os
                      ganhos podem crescer exponencialmente. E lembre-se, muitos membros farão pedidos além dos requisitos,
                      impulsionando ainda mais o seu rendimento.</li>
                  </ol>
                </section>

                <section className="container mx-auto my-8 md:p-4 bg-white md:shadow-lg rounded-md">
                  <h2 className="text-xl font-bold mb-4">Como Participar:</h2>

                  <ol className="list-decimal ml-6">
                    <li className="text-lg mb-2"><strong>Inscrição Simples:</strong> Inscreva-se agora e faça parte da Comunidade
                      Tastic.</li>

                    <form className={'flex flex-col md:flex-row w-full justify-start md:space-x-10 mb-8 space-y-4 md:space-y-0'}>
                      <div className={'w-full md:w-6/12'}>
                        <label htmlFor="email" className="block text-sm font-medium leading-6">
                          {t('Email address')}
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={e => {
                              if (e.target.validity.valid) {
                                e.target.style.border = 'none';
                                setEmail(e.target.value)
                              } else {
                                e.target.style.border = 'solid 2px red';
                              }
                            }}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            pattern="[\-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([cC][oO][mM]))(:[0-9]{1,5})?"
                            required
                            placeholder={'oseuendereço@mail.com'}
                            className="w-full text-2xl rounded-md border-0 bg-gray-600 text-white py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className={'w-full md:w-3/12'}>
                        <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6">
                          Número de Telemóvel
                        </label>
                        <div className="mt-1">
                          <input
                            onChange={e => {
                              if (e.target.validity.valid) {
                                e.target.style.border = 'none';
                                setPhoneNumber(e.target.value)
                              } else {
                                e.target.style.border = 'solid 2px red';
                              }
                            }}
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            autoComplete="phoneNumber"
                            pattern="^[9]{1}[0-9]{8}$"
                            required
                            placeholder={'911234567'}
                            className="block w-full text-2xl rounded-md border-0 bg-gray-600 text-white py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:leading-6"
                          />
                        </div>
                      </div>
                    </form>

                    <li className="text-lg mb-2"><strong>Receba o Seu Link:</strong> Após a inscrição, o seu link ficará ativo. Use este link para convidar outros membros e comece a ganhar
                      imediatamente.</li>

                    <li className="text-lg mb-2"><strong>Recompensas Semanais:</strong> Todas as semanas, enviaremos o seu rendimento
                      diretamente para a conta MBWay ou Revolut associada ao seu telemóvel. Após a inscrição disponibilizamos os link para poder acompanhar os seus resultados.</li>

                    <button className="bg-yellow-500 text-white px-4 py-2 rounded-md mt-4 hover:opacity-80" onClick={() => setPayBy('mbway')}>
                      MBWay <i className="fab fa-cc-apple-pay ml-2"></i>
                      <input type={'checkbox'} checked={payBy === 'mbway'} onChange={() => {}} className={'border-none rounded'}/>
                    </button>
                    <button className="bg-black text-white px-4 py-2 rounded-md mt-4 ml-8 hover:opacity-80" onClick={() => setPayBy('revolut')}>
                      Revolut <i className="fab fa-cc-apple-pay ml-2"></i>
                      <input checked={payBy === 'revolut'} type={'checkbox'} onChange={() => {}} className={'border-none rounded'}/>
                    </button>
                  </ol>
                </section>

                <section className="container mx-auto mt-8 mb-2 p-4 bg-white shadow-lg rounded-md">
                  <h2 className="text-xl font-bold mb-4">Requisitos para Manter a Atividade:</h2>
                  <p className="text-lg">Para se manter como membro ativo, deverá efetuar um mínimo de €30/mês em encomendas ou convidar
                    pelo menos 5 novos membros por mês.</p>
                </section>
              </div>
              }
              {!code ? <div className={`flex flex-col w-full text-center ${!phoneNumber || !email ? 'opacity-50 cursor-none' : ''}`}>
                <button disabled={!phoneNumber || !email} onClick={() => {
                  save();
                }} className={`w-full p-4 md:p-3 bg-primary-600 text-white rounded-full border-0 ${!phoneNumber || !email ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-800'}`}>
                  <span className="font-bold text-xl md:text-2xl">Enviar Inscrição</span>
                </button>
              </div> : <div className={`flex flex-col w-full text-center ${!phoneNumber || !email ? 'opacity-50 cursor-none' : ''}`}>
                <button onClick={() => {
                  navigate('/')
                }} className={`w-full p-4 md:p-3 bg-primary-600 text-white rounded-full border-0 ${!phoneNumber || !email ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-800'}`}>
                  <span className="font-bold text-xl md:text-2xl">Ir para a Aplicação</span>
                </button>
              </div>}
            </div>
            <div className="mt-4 text-center md:text-justify mx-auto mb-8 hidden md:flex">
              <span className="text-xl md:text-3xl z-20  md:font-thin">Food is Fan... </span>
              <span className="text-xl md:text-3xl z-20  md:font-thin">wait for it... </span>
              <span className="text-xl md:text-3xl z-20 font-bold font-signika">tastic!</span>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
